import _ from 'lodash'
import DataTransformTagDimension from '@model/data/DataTransformTagDimension'
import Journey from '@model/Journey'
import ListService from '@serv/ListService'
import Logging from '@serv/Logging'
import Provider from '@model/Provider'
import ResourceService from '@serv/ResourceService'
import store from '@/store'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import User from '@model/User'

const state = {
    activities: undefined,
    activityLists: undefined,
    downloadProgress: 0, // updated from ResourceService.makePromiseForAllResourcesAndReports
    dashSurveyActivity: undefined,
    departments: undefined,
    journeys: undefined,
    hasJourneys: false,
    milestones: undefined, // the base milestone objects, used only to resolve ID to slug
    hasPracticeSurveyResults: false,
    providers: undefined,
    reports: undefined
}

const getters = {
    downloadProgress: state => {
        return state.downloadProgress
    },
    departments: state => {
        return state.departments
    },
    journeys: state => {
        return state.journeys
    },
    hasJourneys: state => {
        return state.hasJourneys
    },
    milestones: state => {
        return state.milestones
    },
    // 22/03/23 Now a map of patientId to patient results
    practiceSurveyResults: () => {
        return SurveyResultsService.practiceSurveyResults
    },
    hasPracticeSurveyResults: state => {
        return state.hasPracticeSurveyResults
    },
    providers: state => {
        return state.providers
    },
    reports: state => {
        return state.reports
    }
}

const actions = {}

/**
 * Hack for mock ProducerExecs, until we implement map from standard to custom journeys
 */
const ownerStandardJourneySlugs = {
    corin: [
        'corin-thr',
        'corin-thr_ops',
        'corin-thrbi',
        'corin-thrbi_ops',
        'corin-tkr',
        'corin-tkr_omni',
        'corin-tkrbi',
        'corin-tkrbi_omni'
    ],
    stry_aus: [
        'stry_aus-pka',
        'stry_aus-pkamako',
        'stry_aus-tha',
        'stry_aus-thamako',
        'stry_aus-tka',
        'stry_aus-tkamako'
    ]
}

function hackJourneysMapToOwnerStandardJourneys(journeysMap, owner) {
    const standardJourneySlugs = ownerStandardJourneySlugs[owner.slug]
    if (standardJourneySlugs) {
        const newMap = {}
        Object.values(journeysMap).forEach(journey => {
            if (standardJourneySlugs.includes(journey.slug)) {
                newMap[journey.slug] = journey
            }
        })

        return newMap
    }

    return journeysMap
}

function resolveJourneys() {
    // Resolve things on each journey
    const journeys = store.state.resources.journeys
    if (Object.keys(journeys).length == 0) {
        return
    }
    Object.values(journeys || {}).forEach(journey => {
        // Post-process journey
        journey.postProcess()
    })
    // If store already has a user that is a patient, resolve any PJ and PJM IDs to slugs
    const user = store.state.user.user
    if (user.isPatient) {
        const patientJourneys = user.allJourneys()
        patientJourneys.forEach(patientJourney => patientJourney.resolveIds())
    }
}

/**
 * Resolves all Provider objects in the map, as follows:
 * - If the Provider is not referenced in any Teams where Team.isLegacy==false, set Provider.isLegacy=true
 */
function resolveProviders() {
    const user = store.state.user.user
    if (user.role == User.Role.hcpCareNavigator) {
        return
    }
    const allProviders = Object.values(store.state.resources.providers || {})
    const allTeams = Object.values(store.state.user.teams || {})
    allProviders.forEach(provider => {
        provider.isLegacy = undefined
        for (const team of allTeams) {
            if (team.providerSlug == provider.slug && !team.isLegacy) {
                provider.isLegacy = false
                break
            }
        }
        if (provider.isLegacy === undefined) {
            provider.isLegacy = true
        }
    })
}

const mutations = {
    setDownloadProgress(state, value) {
        state.downloadProgress = value
    },
    addDepartment(state, department) {
        state.departments = state.departments || {}
        state.departments[department.slug] = department
    },
    setJourneys(state, data) {
        // The input list of journeys may contain duplicates in different zones - here we merge them.
        let journeysMap = {}
        const user = store.state.user.user
        for (const journeyObject of data) {
            const journey = journeysMap[journeyObject.slug]
            if (journey) {
                const newZones = journeyObject.zones != undefined ? journeyObject.zones : [journeyObject.zone]
                journey.zones = _.union(journey.zones, newZones)
            } else {
                journeysMap[journeyObject.slug] = new Journey(journeyObject)
            }
        }
        if (user && user.isMock && user.isProducerExec) {
            journeysMap = hackJourneysMapToOwnerStandardJourneys(journeysMap, user.owner)
        }
        // Apply certain Journey.keyValues.stringMap items to our Locale stringMap
        Object.values(journeysMap).forEach(journey => journey.applyStringMapToLocaleForUser(user))
        state.journeys = journeysMap
        state.hasJourneys = true
    },
    setJourney(state, object) {
        state.journeys = state.journeys || {}
        state.journeys[object.slug] = new Journey(object)
    },
    setMilestones(state, data) {
        let milestonesMap = {}
        for (const milestoneObject of data) {
            milestonesMap[milestoneObject.id] = {
                id: milestoneObject.id,
                type: milestoneObject.type,
                slug: milestoneObject.slug
            }
        }
        state.milestones = milestonesMap
    },
    setProviders(state, data) {
        let providersMap = {}
        data.forEach(providerObject => {
            const provider = new Provider(providerObject)
            providersMap[provider.slug] = provider
        })
        state.providers = providersMap
    },
    setProvider(state, object) {
        state.providers = state.providers || {}
        state.providers[object.slug] = new Provider(object)
    },
    setPracticeSurveyResults(state) {
        state.hasPracticeSurveyResults = true
    },
    setReports(state, reports) {
        state.reports = reports
        /**
         * Useful
         * Log all patient IDs across practice-prom-report
         * const patientIds = [...new Set(resultObjs.map(result => result.patientId))] patientIds.sort()
         */
    },
    setActivityList(state, activityList) {
        const activityListsMap = state.activityLists || {}
        activityListsMap[activityList.slug] = activityList
        state.activityLists = activityListsMap
    },
    setActivity(state, activity) {
        const activitiesMap = state.activities || {}
        activitiesMap[activity.slug] = activity
        state.activities = activitiesMap
    },
    // Dummy mutation to catch error responses
    setError(state, requestStem) {
        Logging.warn(`Error response from: ${requestStem}`)
    },

    /**
     * Called only when all original requests (resources and reports) are completed.
     * We can use this to create references between returned objects.
     */
    resolveResources(state) {
        const user = store.state.user.user
        const surgeons = store.state.user.surgeons

        if (surgeons && user.isProducerExec) {
            // Filter Providers to only those referenced by Surgeons
            const providerSlugsSet = new Set()
            Object.values(surgeons).forEach(surgeon =>
                surgeon.providerSlugs.forEach(slug => providerSlugsSet.add(slug))
            )
            const providersMap = {}
            Object.values(state.providers).forEach(provider => {
                if (providerSlugsSet.has(provider.slug)) {
                    providersMap[provider.slug] = provider
                }
            })
            state.providers = providersMap
        }

        resolveJourneys()
        resolveProviders()
    },
    /**
     * Called only when all original requests (resources and reports) are completed.
     * We can use this to create references between returned objects.
     */
    resolveReports(state) {
        // Extract SurveyResult objects from practice-survey-results response
        let resultObjs = []

        const user = store.state.user.user
        if (user?.isProducerExec) {
            ResourceService.remapReportsJourneySlugs()
        }
        ResourceService.regroupReportsByProcedureTitle()

        // TODO REPORTS change name of report type 'prom', which is misleading -
        // this is ALL completed survey results across all patients.
        if (user?.isPatient) {
            const surveyResults = SurveyResultsService.surveyResultsRemovePartialIfCompleted(state.reports.prom.dataset)
            SurveyResultsService.setPatientSurveyResultsJson(user, surveyResults)
            SurveyResultsService.setPatientSurveyStepResultsJson(user, state.reports.promSteps.dataset)
            user.surveyResultsFromPrm = true
            user.surveyStepResultsFromPrm = true
            ResourceService.postProcessPatient(user)
            user.rebuildScheduleEvents(true)
        } else if (state.reports.prom?.dataset != undefined) {
            resultObjs = state.reports.prom.dataset.map(obj => new SurveyResult(obj))
            SurveyResultsService.setPracticeSurveyResults(resultObjs)
        }
        SurveyResultsService.setSurveyResultChartScheduleSlugs(resultObjs)

        // Remap various properties
        const keyMap = {
            countryIso: 'country'
        }
        Object.values(state.reports).forEach(report => {
            report.dataset.forEach(row => {
                Object.keys(row).forEach(key => {
                    const mappedKey = keyMap[key]
                    if (mappedKey) {
                        row[mappedKey] = row[key]
                        delete row[key]
                    }
                    // Specific handling
                    if (row.country) {
                        row.country = row.country.toUpperCase()
                    }
                })
            })
        })
    },
    resolveDepartments(state) {
        const departmentsMap = state.departments
        if (!departmentsMap) {
            return
        }
        // Add properties from departments report to existing Department objects
        const report = store.state.resources.reports.departmentList
        if (report) {
            report.dataset.forEach(row => {
                const department = departmentsMap[row.departmentSlug]
                if (department) {
                    Object.keys(row).forEach(key => {
                        department[key] = row[key]
                    })
                }
            })
        }

        const departmentListColumns = ListService.getDepartmentListColumns()
        Object.values(state.departments).forEach(department => {
            // Setting department columns references
            department.listColumns = ListService.getListColumnsResolvedForRow(departmentListColumns, department)
        })

        // Set user departments list as tag dimension values on user object
        const user = store.state.user.user
        user.tags.departmentSlug = Object.keys(departmentsMap)
        // Also set the corresponding StringMap entries from the Department objects
        const transform = new DataTransformTagDimension({
            filterSetKey: 'any',
            dimension: 'departmentSlug'
        })
        const tagToStringKey = transform.getTagToStringKey
        Object.keys(tagToStringKey).forEach(tag => {
            const stringKey = tagToStringKey[tag]
            const department = departmentsMap[tag]
            const localeMap = {}
            localeMap[user.locale] = department.title
            user.owner.keyValues.stringMap[stringKey] = localeMap
        })
    },
    ensureActivityLists(state) {
        state.activityLists = state.activityLists || {}
    },
    ensureActivities(state) {
        state.activities = state.activities || {}
    },
    /**
     * Set details of a dash survey.
     */
    setDashSurveyActivity(state, object) {
        state.dashSurveyActivity = object
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
