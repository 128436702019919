import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import store from '@/store'
import SurveyResultsService from '@serv/SurveyResultsService'

/**
 * Resolve a ListColumn of type hopcoClinicalCheck for Patient
 * Grey (0) - if patient has not completed any survey or if one survey is completed with GREEN and the other not completed
 * Green (1) - if all surveys are completed and do not have a RED result
 * Red (2) - If all surveys have a RED response
 * Red (3, 4, etc.) - If any of the surveys have a RED response (no need for both surveys to be completed in order to display red),
 * explicit value depends on hover message for specific survey slug
 */
export function _resolveObjectListColumnOfType_hopcoClinicalCheck(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.hopcoClinicalCheck can only be used on objects of type: Patient`)

        return
    }

    if (column.surveys) {
        let explicitValue = 0
        let redResultsCount = 0
        let greenResultsCount = 0

        for (let survey of column.surveys) {
            const surveyResults = SurveyResultsService.getSurveyResultsCompletedWithinScheduleForPatient(
                row.surveyResults,
                survey.slug,
                'always',
                row
            )

            if (surveyResults.length > 0) {
                if (surveyResults[0].getScore(column.valueScoreSection) == 0) {
                    explicitValue = survey.mapValue || 2
                    redResultsCount++
                } else {
                    greenResultsCount++
                }
            }
        }

        const totalSurveyCount = column.surveys.length

        if (redResultsCount == totalSurveyCount) {
            explicitValue = 2
        }

        if (greenResultsCount == totalSurveyCount) {
            explicitValue = 1
        }

        column.setExplicitValueConfig(explicitValue)
    }
}
export function _resolvePatientJourneyListColumnOfType_hopcoClinicalCheck(column, patientJourney, row) {
    const journey = store.state.resources.journeys[patientJourney.journeySlug]

    const property = column.property || 'hopcoClinicalCheckValue'
    if (row[property] != undefined && (journey?.hasRtm || column.filterPayload?.includeNonRtm)) {
        column.setExplicitValueConfig(row[property])
    }
}
