import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import ResourceService from '@serv/ResourceService'

// List filter for provider column.
class ListTransformProvider extends ListTransform {
    constructor(object) {
        object.filterKey = 'providerTitle'
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    get getFilterConfig() {
        const providers = ResourceService.getPatientListProviders()
        const valueLabels = providers.map(provider => {
            return { value: provider.title, label: provider.title } // filter by title
        })
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: valueLabels.sort((a, b) => a.label.localeCompare(b.label))
        }

        return config
    }

    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row => filterStrings.some(string => row.listColumns[columnIndex].cellText.includes(string)))
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        // Each keyString is provider title
        return keyStrings
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'provider_title'
    }
}

export default ListTransformProvider
