import _ from 'lodash'
import ContentService from '@serv/ContentService'
import GoalMilestone from '@model/GoalMilestone'
import ListColumn from '@model/list/ListColumn'
import ListService from '@serv/ListService'
import Logging from '@serv/Logging'
import MockService from '@serv/MockService'
import moment from 'moment'
import ResourceService from '@serv/ResourceService'
import Schedule from '@model/Schedule'
import store from '@/store'

import Survey from '@model/content/Survey'
import SurveyMockService from '@serv/SurveyMockService'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import Utils from '@serv/Utils'

/**
 * Functions for managing the patient profile, e.g. helpers for Patient Page components.
 * Keep things functional to allow testing without state.
 */
class ClinicianMockService {
    // Get the primary milestone slug
    get primaryMilestoneSlug() {
        return store.state.user.owner.keyValues.dash.charts.primaryMilestone
    }

    // Does owner specify post-op PROM schedules before 1y?
    get hasPostPromSchedulesBefore1y() {
        const scheduleSlugs = store.state.user.owner.keyValues.dash.charts.promSchedules
        for (const scheduleSlug of scheduleSlugs) {
            const schedule = Schedule.get(scheduleSlug)
            if (schedule.startOffset >= 0 && schedule.startOffset < 365) {
                return true
            }
        }

        return false
    }

    // Get the primary Milestone object for the patient
    getPatientPrimaryMilestone(patient) {
        const milestoneSlug = this.primaryMilestoneSlug
        const milestone = patient.getMilestoneOfSlug(milestoneSlug)
        if (milestone == undefined) {
            Logging.warn(`Could not find milestone: ${milestoneSlug} for patient: ${patient.personaId}`)
        } else {
            return milestone
        }
    }

    addMockPatients(numPatients) {
        let i = -1
        while (++i < numPatients) {
            const patient = this.getMockPatient(i, numPatients)
            store.state.user.patients[patient.personaId] = patient
        }
    }

    /**
     * For each patient, primary PJ only, if the patientListColumns specify any keyValue columns, then mock the values
     * inferred from the column spec.
     */
    addMockPatientsKeyValues() {
        const listColumns = ListService.getPatientListColumns()
        const keyValueColumns = listColumns.filter(
            column => column.type == ListColumn.Type.patientJourneyKeyValue || column.type == ListColumn.Type.keyValue
        )
        if (keyValueColumns.length == 0) {
            return
        }
        const patients = Object.values(store.state.user.patients)
        patients.forEach(patient => {
            if (patient.isMock) {
                keyValueColumns.forEach(column => {
                    // Infer value
                    const key = column.key || column.keyValue // legacy support
                    const valueMap = column.valueTextMap || column.valueIconMap
                    const setValue = Math.random() > 0.1 // allow some undefined values
                    if (key && valueMap && setValue) {
                        const value = _.sample(Object.keys(valueMap))
                        if (column.type == ListColumn.Type.patientJourneyKeyValue) {
                            patient.firstJourney.keyValues[key] = value
                        } else {
                            patient.keyValues[key] = value
                        }
                    }
                })
            }
        })
    }

    addMockPatientsResults() {
        const patients = Object.values(store.state.user.patients)
        patients.forEach(patient => {
            if (patient.isMock) {
                patient.pathwayJourneys.forEach(patientJourney => {
                    SurveyMockService.addMockPatientJourneySurveyResults(patient, patientJourney)
                })
                // Goals
                if (this.primaryMilestoneSlug == 'injury' || this.primaryMilestoneSlug == 'appointment') {
                    this.addMockPatientGoalsAndResults(patient)
                }
                // Activity data
                this.addMockPatientActivityData(patient)

                // Prevent any loading on patient page
                patient.hasFullPatientJourney = true
                patient.surveyStepResults = patient.surveyStepResults || []
            }
        })
        // Required to update survey results calculations
        const surveyResults = SurveyResultsService.practiceSurveyResultsArray
        ResourceService.addProcedureTitleToRows(surveyResults)
    }

    /**
     * Add mock activity data.
     */
    addMockPatientActivityData(patient) {
        const milestone = this.getPatientPrimaryMilestone(patient)
        if (milestone == undefined) {
            patient.activityData = []

            return
        }
        const primMoment = milestone.moment

        // Run from -30 to 20 weeks around injury date
        // High before milestone; then drops; then climbs back
        const weekOfsMin = -30
        const weekOfsMax = 20
        const weekOfsVar = weekOfsMax - weekOfsMin
        const stepsMin = 2000
        const stepsVar = 6000
        const activityData = []

        let w = -1
        while (++w < weekOfsVar) {
            const periodMoment = primMoment.clone().add(weekOfsMin + w, 'weeks')
            let steps
            if (w < Math.abs(weekOfsMin)) {
                steps = stepsVar + (-1000 + Math.random() * 2000)
            } else {
                const i = w - Math.abs(weekOfsMin)
                steps = stepsMin + (stepsVar * i) / Math.abs(weekOfsMax) + (-1000 + Math.random() * 2000)
            }
            activityData.push({
                period: periodMoment.format(Utils.serialisedDateFormat),
                dailyStepsAverage: Math.round(steps)
            })
        }
        patient.activityData = activityData
    }

    /**
     * Mock a number of goals, with results, for a patient.
     */
    addMockPatientGoalsAndResults(patient) {
        const goalA = new GoalMilestone({
            id: 100,
            slug: 'goal',
            title: 'Walk up the stairs',
            date: '2021-01-31'
        })
        const goalB = new GoalMilestone({
            id: 101,
            slug: 'goal',
            title: 'Get back to playing rugby',
            date: '2021-02-28'
        })
        const goalC = new GoalMilestone({
            id: 102,
            slug: 'goal',
            title: 'Complete the MOD FAA assessment',
            date: '2021-03-31'
        })
        patient.globalJourney.addMilestone(goalA)
        patient.globalJourney.addMilestone(goalB)
        patient.globalJourney.addMilestone(goalC)

        const surveySlug = 'jhub-goals-surv'
        const scheduleSlug = 'post-reg-rep'
        const results = [
            {
                time: '2021-01-10',
                scoreMap: {
                    100: 50,
                    101: 30,
                    102: 0
                }
            },
            {
                time: '2021-01-20',
                scoreMap: {
                    100: 70,
                    101: 50,
                    102: 10
                }
            },
            {
                time: '2021-01-30',
                scoreMap: {
                    100: 90,
                    101: 70,
                    102: 30
                }
            },
            {
                time: '2021-02-05',
                scoreMap: {
                    100: 100,
                    101: 90,
                    102: 60
                }
            },
            {
                time: '2021-02-10',
                scoreMap: {
                    100: 100,
                    101: 95,
                    102: 80
                }
            }
        ]
        const primaryMilestone = this.getPatientPrimaryMilestone(patient)
        const primaryMilestoneDate = primaryMilestone ? primaryMilestone.date : undefined

        const goalSurveyResults = []
        results.forEach(result => {
            const scoreArray = []
            Object.keys(result.scoreMap).forEach(key => {
                scoreArray.push({ section: key, value: result.scoreMap[key] })
            })
            goalSurveyResults.push(
                new SurveyResult({
                    patientId: patient.personaId,
                    patientJourneyId: patient.globalJourney.patientJourneyId,
                    journeySlug: patient.journeySlug,
                    procedureLabel: patient.procedureLabel,
                    scheduleSlug: scheduleSlug,
                    surveySlug: surveySlug,
                    resultPrmId: this.resultPrmId++,
                    endTime: result.time,
                    scoreArray: scoreArray,
                    primaryMilestoneDate: primaryMilestoneDate
                })
            )
        })
        // Cannot push directly into patientJourney.surveyResults, won't be reactive
        patient.globalJourney.surveyResults = [...patient.globalJourney.surveyResults, ...goalSurveyResults]
        patient.surveyResults = [...patient.surveyResults, ...goalSurveyResults]

        // Append survey results globally
        SurveyResultsService.addPatientPracticeSurveyResults(goalSurveyResults)
    }

    /**
     * - Choose a random set of tag values for each patient, from the user
     * - For all practiceSurveyResults, add the correct tag values for the patient
     */
    addTagsToPatientResultsForUser(user) {
        const patients = Object.values(store.state.user.patients)
        const patientIdToTagValues = {}
        patients.forEach(patient => {
            const keyValues = {}
            Object.keys(user.tags).forEach(tag => {
                keyValues[tag] = _.sample(user.tags[tag])
            })
            patientIdToTagValues[patient.personaId] = keyValues
        })
        // const surveyResults = store.state.resources.practiceSurveyResults
        Object.keys(store.state.resources.practiceSurveyResults).forEach(patientId => {
            const keyValues = patientIdToTagValues[patientId]
            if (keyValues) {
                SurveyResultsService.practiceSurveyResults[patientId].forEach(surveyResult => {
                    Object.keys(keyValues).forEach(key => {
                        if (!surveyResult.hasOwnProperty(key)) {
                            surveyResult[key] = keyValues[key]
                        }
                    })
                })
            }
        })
    }

    /**
     * Create a mock Patient, and all of their key PatientJourneyMilestones.
     * We provide an index in the range [0, N-1] where N is the number of patients being mocked.
     * Milestone specs:
     * invitation: 30-395 or 30-760 days ago
     * appointment: inv date + 3-13 days
     * registration: undefined, OR inv date + 0-3 days
     *
     * IF primaryMilestone==injury: undefined, OR 2-22 days from inv (equally distributed based on index)
     * IF primaryMilestone==operation: undefined, OR -10-405 or -10-805 days from inv (equally distributed based on index)
     */
    getMockPatient(index, numPatients) {
        const rootId = 9000
        const firstName = _.sample(MockService.firstNames)
        const lastName = _.sample(MockService.lastNames)

        // Create milestones
        const maxDaysPost = this.hasPostPromSchedulesBefore1y ? 365 : 730
        const invMoment = moment().subtract(30 + maxDaysPost * Math.random(), 'days')
        const owner = store.state.user.owner
        const primaryMilestoneSlug = owner.keyValues.dash.charts.primaryMilestone

        const invitationDate = invMoment.format(Utils.serialisedDateFormat)
        const appointmentDate = invMoment
            .clone()
            .add(3 + Math.random() * 10, 'days')
            .format(Utils.serialisedDateFormat)

        // Some patients should be unregistered
        const registrationDate =
            Math.random() > 0.2
                ? invMoment
                      .clone()
                      .add(Math.random() * 3, 'days') // up to 3 days from inv
                      .format(Utils.serialisedDateFormat)
                : undefined

        // Some patients should have no primary milestone set
        let primaryDate
        if (index != 0 && Math.random() > 0.3) {
            const daysMin = primaryMilestoneSlug == 'injury' ? 2 : -10
            const daysVar = primaryMilestoneSlug == 'injury' ? 20 : maxDaysPost + 40
            primaryDate = (
                index == 0
                    ? moment() // first patient has primaryMilestone date as today
                    : invMoment.clone().add(daysMin + (index * Math.random() * daysVar) / numPatients, 'days')
            ).format(Utils.serialisedDateFormat)
        } else {
            primaryDate = undefined
        }
        // Pick any valid team
        const providersMap = store.state.resources.providers
        const team = _.sample(
            Object.values(store.state.user.teams).filter(
                team => team.providerSlug && providersMap[team.providerSlug] && team.journeySlugs.length > 0
            )
        )
        const config = {
            // Patient
            userId: rootId + index,
            personaId: rootId + index,
            pincode: '123456',
            sex: _.sample('f', 'm'),
            invitationTime: invMoment.format(Utils.serialisedDateTimeFormat),
            firstName: firstName,
            lastName: lastName,
            dob: moment()
                .add(-(20 + Math.random() * 60), 'years')
                .format(Utils.serialisedDateFormat),
            email: `${firstName}.${lastName}@gmail.com`.toLowerCase(),
            mobile: '+447876141972',
            hospitalNumber: `${MockService.mockPatientHospitalNumber()} (MOCK)`,
            isMock: true,
            // PatientJourney
            teamId: team.id, // defines associated clinician
            journeySlug: _.sample(team.journeySlugs)
        }
        // Special case: implant slugs
        if (owner.slug == 'mprt') {
            if (config.journeySlug.includes('thr')) {
                config.implantSlugs = ['PHA04402', 'PHA01252', 'PRGLHA06']
            } else if (config.journeySlug.includes('tkr')) {
                config.implantSlugs = ['KFTCNP2R', 'KTCCNP40', 'KIMP212L']
            }
        }
        config.invitationDate = invitationDate
        if (registrationDate) {
            config.registrationDate = registrationDate
        }
        config.appointmentDate = appointmentDate
        if (primaryDate) {
            if (primaryMilestoneSlug == 'injury') {
                config.injuryDate = primaryDate
            } else {
                config.operationDate = primaryDate
            }
        }

        // Create Patient with PatientJourney and global journey
        const addSecondary = owner.hasMultiJourney && index == 0
        if (addSecondary) {
            config.numPatientJourneys = 2
        }
        const patient = MockService.mockPatients(config).patient
        if (addSecondary) {
            // Amend secondary journey
            const secondaryJourney = patient.secondaryJourneys[0]
            const journeySlugs = team.journeySlugs.filter(slug => slug != patient.primaryJourney.journeySlug)
            secondaryJourney.journeySlug = _.sample(journeySlugs)
            secondaryJourney.milestones.forEach(milestone => {
                const newDate = milestone.moment
                    .subtract('days', Math.floor(60 + Math.random(90)))
                    .format(Utils.serialisedDateFormat)
                milestone.setDate(newDate)
            })
        }

        return patient
    }

    /**
     * Return a mock report response, based on a report slug and query params object.
     */
    getMockReportWithQueryParams(reportSlug, paramsObject) {
        const numPatients = _.size(store.state.user.patients)
        switch (reportSlug) {
            case 'overview-kpi-report':
                return this.getMockKpiReport(paramsObject, numPatients)

            case 'overview-procedure-summary-report':
                return this.getMockProcedureStatsReport(paramsObject, numPatients)

            case 'overview-prom-compliance-tracker-report':
                return this.getMockProgressTrackerReport(paramsObject)

            case 'overview-prom-survey-results-report':
                return this.getMockPromSurveyResultsReport(paramsObject, numPatients)

            case 'overview-experience-survey-results-report':
                return this.getMockExperienceReport(paramsObject, numPatients)

            default:
                return []
        }
    }

    /**
     * Mock a PROM survey results report.
     * Schedules are from Owner.dash.chart.promSchedules
     */
    getMockPromSurveyResultsReport(paramsObject, numPatients) {
        const journeys = Object.values(store.state.resources.journeys)
        const scheduleSlugs = store.state.user.owner.keyValues.dash.charts.promSchedules
        const patientsPerJourney = numPatients / journeys.length
        const rows = []
        for (const journey of journeys) {
            // Get unique PROM survey slugs on journey
            const surveySlugs = _.uniq(
                journey.activities
                    .map(activity => activity.contentSlug)
                    .filter(contentSlug => {
                        const survey = store.state.content.surveys[contentSlug]

                        return survey && survey.isPromSurvey
                    })
            )
            const allLabels = [undefined, ...journey.procedureLabels]
            const patientsPerLabel = patientsPerJourney / allLabels.length
            for (const procedureLabel of allLabels) {
                for (const surveySlug of surveySlugs) {
                    const survey = store.state.content.surveys[surveySlug]
                    // For each displayable score section
                    const sectionConfigs = (survey.keyValues || {}).scoreSections || [
                        {
                            name: 'Score',
                            minScore: survey.minScore,
                            maxScore: survey.maxScore
                        }
                    ]
                    sectionConfigs.forEach(sectionConfig => {
                        if (sectionConfig.display !== false) {
                            for (let s = 0; s < scheduleSlugs.length; s++) {
                                const scheduleSlug = scheduleSlugs[s]
                                const totalPatients = Math.round(
                                    patientsPerLabel / 2 + Math.random() * patientsPerLabel
                                )
                                const scoreRange = sectionConfig.maxScore - sectionConfig.minScore
                                const scoreMin =
                                    sectionConfig.minScore +
                                    scoreRange / 4 +
                                    (scoreRange / 2) * (s / (scheduleSlugs.length - 1))
                                const scoreVar = (Math.random() * scoreRange) / 4
                                const score = Math.round(scoreMin + Math.random() * scoreVar)
                                rows.push({
                                    numPatients: totalPatients,
                                    procedureCode: journey.procedureCode,
                                    procedureLabel: procedureLabel,
                                    scheduleSlug: scheduleSlug,
                                    scoreSection: `${surveySlug} ${sectionConfig.name}`,
                                    surveySlug: surveySlug,
                                    value: score
                                })
                            }
                        }
                    })
                }
            }
        }

        return rows
    }

    /**
     * Mock an experience survey report response.
     * If the OverviewCptExperience config defines a list of surveys, we use that, otherwise we use all feedback surveys.
     */
    getMockExperienceReport(paramsObject, numPatients) {
        const user = store.state.user.user
        const surveySlugs =
            user.owner.keyValues.dash.overviewPage.components.filter(obj => obj.class == 'OverviewCptExperience')[0]
                .surveySlugs || ContentService.getSurveysWithTag(Survey.feedbackSurveyTag).map(survey => survey.slug)
        const rows = []
        for (const surveySlug of surveySlugs) {
            const survey = store.state.content.surveys[surveySlug]
            if (survey) {
                // For each question
                const questionStepSlugs = survey.questionStepSlugs
                for (const stepSlug of questionStepSlugs) {
                    const step = survey.stepSlugToStep[stepSlug]
                    if (step.choices == undefined) {
                        continue
                    }
                    // Randomly distribute patients across choices
                    const rowsAdded = MockService.mockExperienceReportRows({
                        numPatients: numPatients,
                        survey: survey,
                        step: step
                    })
                    rows.push(...rowsAdded)
                }
            }
        }

        return rows
    }

    getMockProcedureStatsReport(paramsObject, numPatients) {
        let patients = 0
        const journeys = Object.values(store.state.resources.journeys)
        const patientsPerJourney = numPatients / journeys.length
        const rows = []
        for (const journey of journeys) {
            if (patients >= numPatients) {
                break
            }
            const allLabels = [undefined, ...journey.procedureLabels]
            const patientsPerLabel = patientsPerJourney / allLabels.length
            for (const procedureLabel of allLabels) {
                const value = Math.round(patientsPerLabel / 2 + Math.random() * patientsPerLabel)
                rows.push({
                    procedureCode: journey.procedureCode,
                    procedureLabel: procedureLabel,
                    value: value
                })
                patients += value
                if (patients >= numPatients) {
                    break
                }
            }
        }

        return rows
    }

    getMockProgressTrackerReport() {
        const user = store.state.user.user
        const scheduleSlugs = user.owner.keyValues.dash.charts.promSchedules
        const rows = scheduleSlugs.map(scheduleSlug => {
            const row = MockService.mockProgressTrackerReportRow({
                scheduleSlug: scheduleSlug
            })

            return row
        })

        return rows
    }

    getMockKpiPropertyValue(property, numPatients) {
        switch (property) {
            case 'kpiPatientsInvited':
                return numPatients
            case 'kpiPatientsInvitedLast30Days':
                return Math.floor(numPatients / 2 + (Math.random() * numPatients) / 4)
            case 'kpiStepsCollected':
                return Math.floor(100000 + Math.random() * 100000 * numPatients)
            case 'kpiPainResultsCollected':
                return Math.floor(30 + Math.random() * 20 * numPatients)
            default:
                return 0
        }
    }
    getMockKpiReport(paramsObject, numPatients) {
        const user = store.state.user.user
        const kpis = user.owner.keyValues.dash.overviewCptKpis

        const rows = kpis.map(kpiSpec => {
            const value = this.getMockKpiPropertyValue(kpiSpec.property, numPatients)

            return {
                property: kpiSpec.property,
                value: value
            }
        })

        return rows
    }

    /**
     * For each journey (procedureCode), for each activity (surveySlug,scheduleSlug), for each groupingCategory
     * (departmentSlug, providerSlug, region) and each value within, invent totalPatients and average.
     */
    getMockBaselineReport() {
        const rows = []
        const departments = Object.values(store.state.resources.departments || {})
        const providers = Object.values(store.state.resources.providers || {})
        const regions = [...new Set(providers.map(provider => provider.region).filter(region => region))]
        Object.values(store.state.resources.journeys).forEach(journey => {
            journey.activities.forEach(activity => {
                const survey = store.state.content.surveys[activity.contentSlug]
                const schedule = Schedule.get(activity.scheduleSlug)
                if (survey && survey.isClinicalSurvey && schedule.startOffset <= 365) {
                    const groupingDetails = []
                    const scoreBase = Math.max(0, schedule.startOffset) / 365 // 0..1

                    // Each department
                    departments.forEach(department =>
                        groupingDetails.push({
                            category: 'department',
                            field: department.slug,
                            totalPatientsBase: 200
                        })
                    )
                    // Each provider
                    providers.forEach(provider =>
                        groupingDetails.push({
                            category: 'provider',
                            field: provider.slug,
                            totalPatientsBase: 400
                        })
                    )
                    // Each region
                    regions.forEach(region =>
                        groupingDetails.push({
                            category: 'region',
                            field: region,
                            totalPatientsBase: 1000
                        })
                    )
                    groupingDetails.forEach(detail => {
                        rows.push({
                            procedureCode: journey.procedureCode,
                            surveySlug: survey.slug,
                            scheduleSlug: activity.scheduleSlug,
                            groupingCategory: detail.category,
                            groupingField: detail.field,
                            totalPatients:
                                detail.totalPatientsBase + Math.floor(detail.totalPatientsBase * Math.random()),
                            average: Math.round(survey.maxScore * (0.25 + 0.5 * scoreBase + 0.25 * Math.random()))
                        })
                    })
                }
            })
        })

        return rows
    }

    addMockPatientsAndResults(numPatients) {
        const reports = store.state.resources.reports
        const user = store.state.user.user
        this.addMockPatients(numPatients)
        this.addMockPatientsKeyValues()

        const patients = Object.values(store.state.user.patients)
        if (user.isAdminAssistant) {
            patients.forEach(patient => {
                if (patient.isMock) {
                    // Prevent any loading on patient page
                    patient.hasFullPatientJourney = true
                }
            })
        } else {
            this.addMockPatientsResults()
            this.addTagsToPatientResultsForUser(user)

            // Other reports
            if (reports.baseline) {
                reports.baseline.dataset = this.getMockBaselineReport()
            }
        }
    }
}

export default new ClinicianMockService()
