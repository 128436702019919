import Auth from '@serv/Auth'
import axios from 'axios'
import Config from '@serv/Config'
import CorinAuthConfig from '@config/CorinAuthConfig'
import { defineRule } from 'vee-validate'
import Locale from '@serv/Locale'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import StringHelper from '@serv/StringHelper'
import { alpha_num, confirmed, digits, email, max, min, required } from '@vee-validate/rules'

// Map of field names to localisable string IDs.
let fieldNameToStringId = {
    anaesthesia: 'formAnaesthesia',
    dischargeDate: 'inviteDischargeDate',
    dob: 'formDateOfBirth',
    email: 'formEmail',
    'first name': 'formFirstName',
    journey: 'procedure',
    language: 'formLanguage',
    'last name': 'formLastName',
    mobile: 'formMobile',
    name: 'formName',
    operationDate: 'formOperationDate',
    password: 'formPassword',
    patientId: 'formPatientId',
    pincode: 'formPincode',
    provider: 'provider',
    sex: 'formSex',
    surgeon: 'surgeon',
    temporaryPassword: 'formTempPassword',
    title: 'formTitle',
    username: 'formUsername'
}

/**
 * Attempt to get localised field name, using map to string IDs.
 */
function getLocalisedFieldName(field) {
    if (fieldNameToStringId.hasOwnProperty(field)) {
        let stringId = fieldNameToStringId[field]

        return Locale.getLanguageItem(stringId).toLowerCase()
    }

    return field
}

function getCorinUser(value) {
    const url = `${CorinAuthConfig.userApi[Config.environment]}${value}`
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Auth.session.accessToken}`
        }
    }
    const params = null

    return axios
        .get(url, {
            params,
            options
        })
        .then(res => {
            return res.data
        })
        .catch(() => {
            return {
                error: true
            }
        })
}
function validateWithArgument(field, args, string) {
    return Locale.getLanguageItem(string, [getLocalisedFieldName(field), args])
}
function validateWithField(field, string) {
    const test = getLocalisedFieldName(field)

    return Locale.getLanguageItem(string, [test])
}
class Validators {
    setCustomValidation() {
        defineRule('required', value => {
            return required(value) || Locale.getLanguageItem('validationRequired')
        })
        defineRule('email', value => email(value) || Locale.getLanguageItem('validationEmail'))
        defineRule(
            'no_spaces',
            (value, args, { field }) => !/\s/.test(value) || validateWithField(field, 'validationNoSpaces')
        )
        defineRule(
            'no_spaces_or_symbols',
            (value, args, { field }) =>
                /^[A-Za-z0-9]+$/.test(value) || validateWithField(field, 'validationNoSpacesOrSymbols')
        )
        defineRule(
            'max',
            (value, args, { field }) => max(value, args) || validateWithArgument(field, args, 'validationMax')
        )
        defineRule(
            'min',
            (value, args, { field }) => min(value, args) || validateWithArgument(field, args, 'validationMin')
        )
        defineRule('alpha_num', (value, args) => alpha_num(value, args) || Locale.getLanguageItem('validationAlphaNum'))
        defineRule(
            'digits',
            (value, args, { field }) => digits(value, args) || validateWithArgument(field, args, 'validationDigits')
        )
        defineRule('confirmed', (value, args, { field }) => {
            return confirmed(value, args) || validateWithArgument(field, args, 'validationMatch')
        })
        defineRule('password_standard', value => {
            const tester = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g

            return tester.test(value) || Locale.getLanguageItem('validationPasswordStandard')
        })
        defineRule('phone', (value = '', args, { field }) => {
            if (!value) {
                return true
            }

            const number = parsePhoneNumberFromString(value)

            return number?.isValid() || validateWithField(field, 'validationPhoneNumber')
        })
        defineRule('phone_prefix', value => {
            return String(value).startsWith('+') || Locale.getLanguageItem('validationCountryCode')
        })
        defineRule('username', (value, args, { field }) => {
            const allDigits = /^\d+$/.test(value)
            const validate = value => {
                if (allDigits || !value.includes('@')) {
                    const number = parsePhoneNumberFromString(value)

                    return number ? number.isValid() : false
                }

                return StringHelper.isValidEmail(value)
            }

            return validate(value) || validateWithField(field, 'validationUsername')
        })
        defineRule('fullname', value => {
            return (
                value?.split(' ').filter(val => val !== '').length > 1 || Locale.getLanguageItem('validationFullName')
            )
        })
        defineRule('corinEmail', value => {
            const validate = value => {
                const lowerCasedValue = value.toLowerCase()

                return getCorinUser(lowerCasedValue).then(response => {
                    if (response.userDetails) {
                        const emailString = response.userDetails.email.toLowerCase()
                        if (lowerCasedValue !== emailString) {
                            return Locale.getLanguageItem('corinValidationEmail')
                        }
                        if ('Surgeon' !== response.userDetails.userType) {
                            return Locale.getLanguageItem('corinValidationUser')
                        }
                        if (!response.userDetails.isActive) {
                            return Locale.getLanguageItem('corinValidationActive')
                        }

                        return true
                    }

                    return Locale.getLanguageItem('corinValidationEmail')
                })
            }

            return validate(value)
        })
        defineRule('corinPersona', async value => {
            const response = await getCorinUser(value)

            return (
                (response.userDetails && 'Surgeon' === response.userDetails.userType) ||
                Locale.getLanguageItem('corinValidationUser')
            )
        })
        defineRule('corinActive', async value => {
            const response = await getCorinUser(value)

            return (
                (response.userDetails && !!response.userDetails.isActive) ||
                Locale.getLanguageItem('corinValidationActive')
            )
        })
        defineRule('time', value => {
            const validate = value => {
                const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':']
                const valueChars = (value || '').split('')
                const invalid = valueChars.some(char => !chars.includes(char))

                return !invalid || Locale.getLanguageItem('validationRequired')
            }

            return validate(value)
        })
        defineRule('afterDate', (value, args, { field, startDate }) => {
            const validate = value => {
                if (!startDate) {
                    return true
                }

                return value >= startDate
            }
            const message = (field, args) => {
                const startDateFieldName = args['startDateFieldName']
                    ? Locale.getLowercaseForLocale(Locale.getLanguageItem(args.startDateFieldName))
                    : ''

                return Locale.getLanguageItem('validationAfterDate', [
                    Locale.getLowercaseForLocale(Locale.getLanguageItem(field)),
                    startDateFieldName
                ])
            }

            return validate(value) || message(field, args)
        })
        defineRule('hospitalNumberNhs', value => {
            const validate = value => {
                // Remove spaces from NHS number
                const noSpacesValue = value.replace(/ /g, '')
                // Must be 10 digits
                if (noSpacesValue.length !== 10) {
                    return false
                }

                // Checks that noSpacesValue only contains numeric characters
                return /^\d*$/.test(noSpacesValue)
            }

            return validate(value) || Locale.getLanguageItem('validationHospitalNumberNhs')
        })
        defineRule('validateMbiNumber', value => {
            if (!value) {
                return true
            }

            /**
             * https://www.cms.gov/medicare/new-medicare-card/understanding-the-mbi-with-format.pdf
             * The MBI has 11 characters. MBIs are numbers and upper-case letters:
             * The 2nd, 5th, 8th and 9th characters are always letters [A-Z] (except S,L,O,I,B,Z)
             * The 1st [1-9], 4th, 7th, 10th and 11th are always numbers [0-9]
             * The 3rd and 6th characters are letters or numbers [A-Z](except S,L,O,I,B,Z) or [0-9]
             * No dashes in the MBI
             */
            const mbiNumberRegex =
                /\b[1-9](?![SLOIBZ)])[A-Z](?![SLOIBZ)])[A-Z\d]\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z\d]\d(?![SLOIBZ])[A-Z](?![SLOIBZ])[A-Z]\d{2}\b/

            return mbiNumberRegex.test(value) || Locale.getLanguageItem('validationMbiNumber')
        })

        defineRule('emailOrPhone', (value, [target], ctx) => {
            if (value || ctx.form[target]) {
                return true
            }

            return Locale.getLanguageItem('patientRegUsernameRequired')
        })
    }
}

export default new Validators()
