<template lang="pug">
extends PopupTemplate.pug
block input
    <h3 v-if=!!config.title class="popup__title">{{ config.title }}</h3>

    <div class="popup__text">{{ config.text }}</div>

    <div class="popup__cta-wrapper text-center">
        <button @click="handleLeftButtonClick" class="mr-btn mr-btn--primary popup__cta-wrapper__button" data-cy="submit-btn" :class="{'mr-btn--secondary': config.isLeftButtonSecondary }">{{ config.leftButtonText }}</button>
        <button @click="config.onRightButton(config)" class="mr-btn mr-btn--primary popup__cta-wrapper__button" data-cy="secondary-btn">{{ config.rightButtonText }}</button>
    </div>
</template>

<script>
import PopupMixin from '@mixins/PopupMixin'

export default {
    name: 'PopupTwoButton',
    mixins: [PopupMixin]
}
</script>

<style lang="scss" scoped>
/* stylelint-disable-next-line selector-class-pattern */
.popup__cta-wrapper__button {
    margin-top: 20px;
    margin-right: 16px;
}
</style>
