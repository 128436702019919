import * as Sentry from '@sentry/vue'
import Analytics from '@serv/Analytics'
import Auth from '@serv/Auth'
import Auth0 from '@serv/Auth/Auth0'
import ChatService from '@serv/ChatService'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'
import ListService from '@serv/ListService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import NotifyService from '@serv/NotifyService'
import Redirect from '@serv/Redirect'
import Request from '@serv/Request'
import Storage from '@serv/Storage'
import store from '@/store'
import { useAdminStore } from '@/stores/admin'
import Whoami from '@serv/Whoami'

/**
 * Helpers for managing the user login and logout process..
 */
class LoginService {
    // Log the user out, but do not navigate anywhere.
    logout(config) {
        Logging.log('Logging out...')

        // Logout of 3rd party services
        Analytics.logOut()
        ChatService.disconnect()
        Sentry.setUser()

        // Display logout notification after 1 second
        const currentRoute = Redirect.router.currentRoute.value
        let messageText, messageFunction
        if (ConfigManager.config.client !== 'corin' && !config?.ignoreNotification) {
            if (currentRoute.params && currentRoute.notAuthorised) {
                messageText = Locale.getLanguageItem('loginUnauthorisedFromFrontEnd')
                messageFunction = 'error'
            } else if (currentRoute.query.expired) {
                messageText = Locale.getLanguageItem('accountLogOutSessionExpired')
                messageFunction = 'error'
            } else {
                messageText = Locale.getLanguageItem('accountLogOutOccurred')
                messageFunction = 'info'
            }
        }
        if (messageText) {
            NotifyService[messageFunction](messageText, 1000)
        }
        Request.removeErrorCallback(401)
        Whoami.invalidate()
        const region = Config.country
        Config.reset() // e.g. clear 'country' i.e. region

        // Remove everything from local storage
        Storage.remove('mr_session')
        Storage.remove('spoof')

        // Reset entire Vuex state
        store.commit('reset')

        // Reset Pinia stores
        useAdminStore().$reset()

        // Reset other globals
        ListService.reset()

        return new Promise((resolve, reject) => {
            if (Storage.get('auth_source') == 'auth0' || config?.isAuth0) {
                Auth0.reset()
                Auth0.revokeToken(region || config?.region)
                reject()
            } else {
                Auth.reset()
                Auth.revokeToken()
                resolve()
            }
        })
    }
}

export default new LoginService()
