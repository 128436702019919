import _ from 'lodash'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import ClinicianMockService from '@serv/ClinicianMockService'
import ConfigManager from '@config/ConfigManager'
import DataService from '@serv/DataService'
import ExecMockService from '@serv/ExecMockService'
import ListService from '@serv/ListService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Owner from '@model/Owner'
import Patient from '@model/Patient'
import ResourceService from '@serv/ResourceService'
import store from '@src/store/index'
import SurveyResultsService from '@serv/SurveyResultsService'
import Team from '@model/Team'
import User from '@model/User'
import UserFactory from '@serv/UserFactory'

const state = {
    corinAuthed: false,
    owner: {},
    patients: undefined, // from getPatients
    hasPatients: false,
    hasPatientJourneyListResponse: false,
    patientJourneys: undefined, // from getPatients
    surgeons: undefined, // from getSurgeons, only for execs. Indexed by personaUid
    teams: undefined, // from getTeams, only for non-execs
    teamLeads: undefined, // from resolveUsers(), all leads from user or users. Map of personaId -> object
    user: undefined, // from getUser(), i.e. logged-in user only
    users: undefined // from getTeams(), does NOT include the logged-in user. Map of personaId -> object
}

const getters = {
    corinAuthed: state => {
        return state.corinAuthed
    },
    owner: state => {
        return state.owner
    },
    patients: state => {
        return state.patients
    },
    hasPatients: state => {
        return state.hasPatients
    },
    hasPatientJourneyListResponse: state => {
        return state.hasPatientJourneyListResponse
    },
    patientJourneys: state => {
        return state.patientJourneys
    },
    surgeons: state => {
        return state.surgeons
    },
    teams: state => {
        return state.teams
    },
    teamLeads: state => {
        return state.teamLeads
    },
    user: state => {
        return state.user
    },
    users: state => {
        return state.users
    }
}

const actions = {
    loadCorinAuthed({ commit }) {
        commit('setCorinAuthed', true)
    },
    clearSurgeonList({ commit }) {
        commit('clearSurgeons', '')
    },
    clearPatientList({ commit }) {
        commit('clearPatients', undefined)
    },
    setSurgeons({ commit }, surgeons) {
        commit('setSurgeons', surgeons)
    },
    setPatients({ commit }, patients) {
        commit('setPatients', patients)
    },
    updatePatientJourney({ commit }, patientJourney) {
        commit('updatePatientJourney', patientJourney)
    },
    setPatientJourneys({ commit }, patientJourneys) {
        commit('setPatientJourneys', patientJourneys)
    },
    setTeams({ commit }, teams) {
        commit('setTeams', teams)
    },
    setTeamLeads({ commit }, teamLeads) {
        commit('setTeamLeads', teamLeads)
    },
    setUsers({ commit }, users) {
        commit('setUsers', users)
    }
}

const mutations = {
    setAvatar(state, data) {
        state.user.avatarUrl = data
    },
    setCorinAuthed(state, data) {
        state.corinAuthed = data
    },
    ensurePatients(state) {
        state.patients = state.patients || {}
        state.patientJourneys = state.patientJourneys || {}
    },
    setPatients(state, data) {
        if (!data) {
            state.patients = undefined

            return
        }

        let patientsMap = {}
        let patientJourneysMap = {}

        // Debugging: filter response to single patient
        if (ConfigManager.singlePatientId) {
            data = data.filter(patient => patient.personaId == ConfigManager.singlePatientId)
            if (data.length == 1) {
                Logging.log(`Filtered patients response to single patient: ${JSON.stringify(data[0], null, 2)}`)
            }
        }

        // Iterate, potentially skipping any malformed patients
        for (const patientObject of data) {
            const patient = new Patient(patientObject)
            if (!patient.firstJourney) {
                Logging.warn(`Patient ${patient.patientId} has undefined firstJourney, removing...`)
                continue
            }
            patientsMap[patient.personaId] = patient
            const allJourneys = [...patient.allJourneys(true)]
            allJourneys.forEach(
                patientJourney => (patientJourneysMap[patientJourney.patientJourneyId] = patientJourney)
            )
        }
        state.patients = patientsMap
        state.hasPatients = true
        // Only set patientJourneys state if not already initialised, e.g. by Patient user
        if (state.patientJourneys == undefined) {
            state.patientJourneys = patientJourneysMap
        }
    },
    addPatient(state, patient) {
        if (state.patients[patient.personaId]) {
            Logging.error(`Patient ${patient.personaId} already exists`)
        }
        state.patients[patient.personaId] = patient
        state.hasPatients = true
        // Add all PatientJourneys to state
        patient
            .allJourneys()
            .forEach(patientJourney => (state.patientJourneys[patientJourney.patientJourneyId] = patientJourney))
    },
    setPatient(state, patient) {
        state.patients[patient.personaId] = patient
        state.hasPatients = true
        // Add all PatientJourneys to state
        patient
            .allJourneys()
            .forEach(patientJourney => (state.patientJourneys[patientJourney.patientJourneyId] = patientJourney))
    },
    addPatientJourney(state, patientJourney) {
        if (state.patientJourneys[patientJourney.patientJourneyId]) {
            Logging.error(`PatientJourney ${patientJourney.patientJourneyId} already exists`)
        }
        const patient = state.patients[patientJourney.patientId]
        if (!patient) {
            Logging.error(`PatientJourney patient ${patientJourney.patientId} not found`)
        }
        state.patientJourneys[patientJourney.patientJourneyId] = patientJourney
    },
    removePatientJourney(state, patientJourney) {
        if (!state.patientJourneys[patientJourney.patientJourneyId]) {
            Logging.error(`PatientJourney ${patientJourney.patientJourneyId} does not exist`)
        }
        const patient = state.patients[patientJourney.patientId]
        if (!patient) {
            Logging.error(`PatientJourney patient ${patientJourney.patientId} not found`)
        }
        delete state.patientJourneys[patientJourney.patientJourneyId]
    },
    updatePatient(state, object) {
        const patient = state.patients[object.personaId]
        if (patient) {
            patient.setPatientFields(object)
        } else {
            Logging.error(`Could not find existing patient with patientId ${object.patientId}`)
        }
    },
    updatePatientJourney(state, object) {
        const patientJourney = state.patientJourneys[object.patientJourneyId]
        if (patientJourney) {
            patientJourney.setPatientJourneyFields(object)
        } else {
            Logging.error(`Could not find existing patientJourney with patientJourneyId ${object.patientJourneyId}`)
        }
    },
    setPatientActivityData(state, res) {
        const patient = state.patients[res.patientId]
        patient.activityData = res.weeklyData
        patient.activityDataDaily = res.dailyData
    },
    setPatientSurveyResults(state, res) {
        const patient = state.patients[res.patientId]
        SurveyResultsService.setPatientSurveyResultsJson(patient, res.dataset)
    },
    setPatientSurveyStepResults(state, res) {
        const patient = state.patients[res.patientId]
        SurveyResultsService.setPatientSurveyStepResultsJson(patient, res.dataset)
    },
    setPatientExerciseResults(state, res) {
        const patient = state.patients[res.patientId]
        patient.exerciseResults = res.dataset
    },
    setPatientClinicalMilestones(state, res) {
        const patient = state.patients[res.patientId]
        patient.clinicalMilestones = res.data
    },
    setPatientListColumns(state, res) {
        const patient = state.patients[res.patientId]
        patient.listColumns = res.data
    },
    setHasPatientJourneyListResponse(state, status) {
        state.hasPatientJourneyListResponse = status
    },
    setClinicianListColumns(state, res) {
        const clinician = state.surgeons[res.personaId]
        clinician.listColumns = res.data
    },
    setSurgeons(state, data) {
        if (!data) {
            state.surgeons = undefined

            return
        }

        state.surgeons = UserFactory.parseUsersArray(data, true)

        const users = {}
        Object.values(state.surgeons).forEach(surgeon => {
            users[surgeon.personaId] = surgeon
        })
        state.users = users
    },
    updateClinician(state, object) {
        const clinician = state.surgeons[object.personaUid]
        if (clinician) {
            // Currently only allow update of User field
            clinician.setUserFields(object)
        } else {
            Logging.error(`Could not find existing clinician with personaUid ${object.personaUid}`)
        }
    },
    clearSurgeons(state, data) {
        state.surgeons = data
    },
    clearPatients(state, data) {
        state.patients = data
    },
    setTeams(state, data) {
        if (!data) {
            state.teams = undefined

            return
        }

        let teamsMap = {}
        if (Array.isArray(data)) {
            data.forEach(teamObject => {
                const team = new Team(teamObject)
                teamsMap[team.id] = team
            })
        }
        if (state.user?.isProducerExec) {
            // Remap journeySlugs to standards
            Object.values(teamsMap).forEach(team => {
                const remappedSlugs = ResourceService.remapJourneySlugsForOwner(team.journeySlugs, state.owner)
                team.journeySlugs = [...new Set(remappedSlugs)]
            })
        }
        state.teams = teamsMap
    },
    setTeam(state, object) {
        state.teams = state.teams || {}
        state.teams[object.id] = new Team(object)
    },
    setTeamMembers(state, data) {
        const users = UserFactory.parseUsersArray(data)
        // Add the logged-in user
        if (state.user.isClinician) {
            users[state.user.personaId] = state.user
        }
        state.users = users
    },
    setLead(state, object) {
        state.users = state.users || {}
        const idToObject = UserFactory.parseUsersArray([object])
        const personaId = Object.keys(idToObject)[0]
        const user = Object.values(idToObject)[0]
        state.users = state.users || {}
        state.users[personaId] = user
        state.teamLeads = state.teamLeads || {}
        state.teamLeads[personaId] = user
    },
    setUser(state, user) {
        if (!(user instanceof User)) {
            throw new Error('Calling setUser() with an object that is not a User')
        }
        // Currently the Owner (organisation) is serialised within the User response
        state.user = user

        if (user instanceof Patient) {
            // Initialise Patient and PatientJourneys from single Patient
            state.patients = {}
            state.patients[user.patientId] = user

            state.patientJourneys = {}
            user.allJourneys().forEach(patientJourney => {
                state.patientJourneys[patientJourney.patientJourneyId] = patientJourney
            })
        }

        // Initialise global overview filter set
        const key = 'overview'
        if (!store.state.data.filterSets[key]) {
            DataService.createFilterSet(user, key)
        }
    },
    setOwnerFromUserObject(state, user) {
        if (user.owner) {
            const owner = new Owner(user.owner)
            state.owner = owner

            // Perform keyValue filtering based on user role
            owner.keyValues = DataService.filterConfigObjectWithRole(owner.keyValues, user.role)

            // If the Owner specifies any stringMap, pass this to our locale service
            if (owner?.keyValues?.stringMap) {
                Locale.stringMap = owner.keyValues.stringMap
            }
            if (owner?.keyValues?.dash?.patientListColumns && user.capabilities.includes(User.Capability.canChat)) {
                ListService.processPatientListColumns(owner.keyValues.dash.patientListColumns)
            }
        } else {
            // Mock owner
            user.owner = new Owner({
                keyValues: {
                    dash: {
                        charts: {}
                    }
                }
            })
            state.owner = user.owner
        }
    },
    /**
     * Called only when ALL responses called through getResources() have responded.
     * We can use this to create references between returned objects.
     */
    resolveUsers(state) {
        if (!state.users) {
            return
        }
        // Build state.teamLeads
        if (state.user.isExec) {
            // state.teamLeads will be all objects from surgeons response
            const teamLeads = {}
            Object.values(state.surgeons).forEach(surgeon => {
                teamLeads[surgeon.personaId] = surgeon
            })
            state.teamLeads = teamLeads
        } else {
            // teamLeads should include the logged-in user (if a lead), and any other leads from within teams
            const teamLeads = {}
            const teamLeadIds = Object.values(state.teams || {}).map(team => team.leadId)
            if (teamLeadIds.includes(state.user.personaId)) {
                // Logged-in user is referenced as a team lead
                teamLeads[state.user.personaId] = state.user
            }
            Object.keys(state.teams || {}).forEach(teamId => {
                const team = state.teams[teamId]
                if (!teamLeads[team.leadId] && state.users[team.leadId]) {
                    teamLeads[team.leadId] = state.users[team.leadId]
                }
            })
            state.teamLeads = teamLeads
        }

        // Ensure ALL persona objects have:
        // providerSlugs ... for all providers they are leads with
        // journeySlugs ... for all journeys across all teams
        const allUsers = [state.user, ...Object.values(state.users || {})]
        allUsers.forEach(user => {
            // All Teams with this user
            const userTeams = Object.values(state.teams || {}).filter(
                team => team.leadId == user.personaId || team.memberIds.includes(user.personaId)
            )
            // All provider slugs across teams for this user
            if (user.providerSlugs == undefined || user.providerSlugs.length == 0) {
                user.providerSlugs = _.uniq(userTeams.map(team => team.providerSlug))
            }

            // All journey slugs across teams for this user
            if (user.journeySlugs == undefined || user.journeySlugs.length == 0) {
                user.journeySlugs = userTeams.reduce(
                    (slugs, team) => new Set([...slugs, ...team.journeySlugs]),
                    new Set()
                )
                user.journeySlugs = [...user.journeySlugs]
            }

            user.journeySlugs = [...user.journeySlugs]
        })
        // Resolve departmentSlugs
        allUsers
            .filter(user => user.isClinician)
            .forEach(clinician => {
                if (store.state.user.teams) {
                    const departmentSlugSet = new Set()
                    Object.values(store.state.user.teams).forEach(team => {
                        if (team.hasClinician(clinician) && team.departmentSlug) {
                            departmentSlugSet.add(team.departmentSlug)
                        }
                    })
                    clinician.departmentSlugs = [...departmentSlugSet]
                } else {
                    clinician.departmentSlugs = []
                }
            })
    },
    /**
     * Called only when ALL resource and reports requests have returned.
     * We can use this to create references between returned objects.
     * If a single patient is specified, just resolve that (e.g. called after patient invite).
     *
     */
    resolvePatients(state, singlePatient) {
        if (!state.patients) {
            return
        }
        const user = state.user
        const patientListColumns = user.isAdmin ? [] : ListService.getPatientListColumns()

        if (!singlePatient) {
            // Allow mocked data
            if (user.isMock) {
                const numPatients = 20
                ClinicianMockService.addMockPatientsAndResults(numPatients)
            }
        }

        let patients = singlePatient ? [singlePatient] : Object.values(state.patients)
        const patientJourneys = singlePatient ? singlePatient.allJourneys() : Object.values(state.patientJourneys)

        // Resolve journeys to slugs
        patientJourneys.forEach(patientJourney => patientJourney.resolveIds())

        // Filter out badly formed patients, and patients with journeys which were not resolved to slugs
        if (!singlePatient) {
            patients = patients.filter(patient => {
                if (!patient.firstJourney.journeySlug) {
                    Logging.warn(
                        `Could not find slug for journey ${patient.firstJourney.journeyId} - this patient ${patient.personaId} will be filtered out.`
                    )

                    return
                }

                return patient.globalJourney && patient.firstJourney
            })
            if (patients.length != Object.values(state.patients).length) {
                state.patients = {}
                patients.forEach(patient => (state.patients[patient.personaId] = patient))
            }
        }

        if (store.state.resources.reports.video) {
            store.state.resources.reports.video.dataset.forEach(row => {
                const patient = state.patients[row.patientId]
                if (patient) {
                    Object.keys(row).forEach(key => {
                        if (key != 'patientId') {
                            patient[key] = row[key]
                        }
                    })
                }
            })
        }

        patients.forEach(patient => {
            const surveyResults = SurveyResultsService.practiceSurveyResults[patient.patientId] || []
            patient.setSurveyResults(surveyResults)

            // Evaluate and resolve ScheduleEvents (skipped in some scenarios)
            patient.rebuildScheduleEvents()

            const clinicalMilestones = ClinicalMilestoneService.getClinicalMilestonesResolvedForPatientJourney(
                patient,
                patient.firstJourney
            )
            patient.clinicalMilestones = clinicalMilestones

            const owner = store.state.user.owner
            if (!user.isAdmin && !owner.hasPatientJourneyList) {
                // Evaluate ListColumns for patient
                const listColumns = ListService.getListColumnsResolvedForRow(patientListColumns, patient)
                patient.listColumns = listColumns
            }
        })
    },
    // Called only for execs
    resolveClinicians(state) {
        // Allow mocked data
        const user = state.user
        if (user.isAdmin) {
            return
        }
        if (user.isMock) {
            ExecMockService.addMockData(user)
        }

        // Inline tags into Clinician objects
        DataService.addTagsToObjects(Object.values(state.surgeons))

        // Inline tags into reports rows
        DataService.addTagsToReportsRows(store.state.resources.reports)

        Object.values(state.surgeons).forEach(clinician => {
            if (user.isProducerExec) {
                // Remap journeySlugs to standards?
                const remappedSlugs = ResourceService.remapJourneySlugsForOwner(clinician.journeySlugs, user.owner)
                clinician.journeySlugs = [...new Set(remappedSlugs)]
            }
        })

        // Copy values from clinician-list-report onto Clinician objects
        const report = store.state.resources.reports.clinicianList
        if (report) {
            report.dataset.forEach(row => {
                const clinician = state.surgeons[row.personaUid]
                if (clinician) {
                    Object.keys(row).forEach(key => {
                        clinician[key] = row[key]
                    })
                }
            })
        }

        // Evaluate ListColumns
        const clinicianListColumns = ListService.getClinicianListColumns()
        Object.values(state.surgeons).forEach(surgeon => {
            const listColumns = ListService.getListColumnsResolvedForRow(clinicianListColumns, surgeon)
            surgeon.listColumns = listColumns
        })
    },
    setPatientJourneys(state, patientJourneys) {
        if (!patientJourneys) {
            state.patientJourneys = undefined
        }
    },
    setTeamLeads(state, teamLeads) {
        if (!teamLeads) {
            state.teamLeads = undefined
        }
    },
    setUsers(state, users) {
        if (!users) {
            state.users = undefined
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
