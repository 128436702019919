import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import store from '@/store'
import Utils from '@serv/Utils'

export function _resolveObjectListColumnOfType_teamLead(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.teamLead can only be used on objects of type: Patient`)

        return
    }
    const patient = row
    const users = PatientService.getPatientLeads(patient)
    column.teamLead = users.length > 0 ? users[0] : undefined // to assist with sorting
    Utils.sortUsersByLastThenFirstNames(users)
    column.cellText = users
        .map(user => (column.hideRole ? user.titledFullName : user.titledFullNameWithRole))
        .join(', ')
}
export function _resolvePatientJourneyListColumnOfType_teamLead(column, patientJourney, row) {
    if (row.teamId) {
        const team = store.state.user.teams[row.teamId]
        if (team) {
            const teamLead = store.state.user.users[team.leadId]

            if (teamLead) {
                column.cellText = column.hideRole ? teamLead.titledFullName : teamLead.titledFullNameWithRole
            } else {
                Logging.error(`Team lead not found for teamId: ${row.teamId}`)
            }
        }
    }
}
