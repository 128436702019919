import Config from '@serv/Config'
import moment from 'moment'
import Request from '@serv/Request'
import store from '@/store'

import User from '@model/User'
import Utils from '@serv/Utils'

class ReportService {
    downloadReport(builtSlug) {
        const url = Request.Stem.reportsDownload.replace('${reportSlug}', builtSlug)

        return Request.get(url).then(res => {
            return res.data
        })
    }

    // Get a specific report.
    getOverviewReport(slug, type, id) {
        const url = Request.Stem.overviewReports.replace('{type}', type).replace('{id}', id).replace('{slug}', slug)

        return Request.get(url).then(res => res.data)
    }

    /**
     * Get activity data for a patient.
     * If isDaily, we get the daily report, else the weekly report.
     */
    getActivity(patientJourneyId, isDaily) {
        const url = Request.Stem.patientJourneyReport
            .replace('{patientJourneyId}', patientJourneyId)
            .replace('{reportSlug}', isDaily ? 'patient-daily-activity-report3' : 'patient-activity-report3')

        return Request.get(url).then(res => {
            return res.data
        })
    }
    // Given an array of daily activity data, return an array rolled up to weekly data.
    getActivityDataWeeklyFromDaily(dailyData) {
        const weeklyData = []
        let weekStartMoment
        let weekTotal
        let weekDataDays
        dailyData.forEach(day => {
            if (!weekStartMoment) {
                // Once only, at start of whole series
                weekDataDays = 1
                weekTotal = day.dailySteps
                weekStartMoment = new moment(day.period).startOf('isoWeek') // Monday <= this date
            } else {
                const dayMoment = new moment(day.period)
                if (dayMoment.diff(weekStartMoment, 'days') >= 7) {
                    const period = weekStartMoment.format(Utils.serialisedDateFormat)
                    const avgDailyTotal = Math.floor(weekTotal / Math.max(1, weekDataDays))
                    weeklyData.push({
                        period: period,
                        dailyStepsAverage: avgDailyTotal
                    })
                    // Reset from new week
                    weekDataDays = 1
                    weekTotal = day.dailySteps
                    weekStartMoment = new moment(day.period).startOf('isoWeek')
                } else {
                    weekDataDays++
                    weekTotal += day.dailySteps
                }
            }
        })
        if (weekStartMoment) {
            const period = weekStartMoment.format(Utils.serialisedDateFormat)
            const avgDailyTotal = Math.floor(weekTotal / Math.max(1, weekDataDays))
            weeklyData.push({
                period: period,
                dailyStepsAverage: avgDailyTotal
            })
        }

        return weeklyData
    }

    getPatientSurveyResultsUrl(patientJourneyId, removeFilterInactive) {
        let url = `${Request.Stem.patientJourneyReport
            .replace('{patientJourneyId}', patientJourneyId)
            .replace('{reportSlug}', 'patient-survey-results-prm-report')}&db=${Config.country}`
        if (removeFilterInactive) {
            url = url.replace('filter=includeInactive&', '')
        }

        return url
    }
    // Get all patient survey results (now from the PRM).
    getPatientSurveyResults(patientJourneyId) {
        const url = this.getPatientSurveyResultsUrl(patientJourneyId)

        return Request.get(url).then(res => {
            return res.data
        })
    }
    getPatientSurveyStepResultsUrl(patientJourneyId, removeFilterInactive) {
        let url = `${Request.Stem.patientJourneyReport
            .replace('{patientJourneyId}', patientJourneyId)
            .replace('{reportSlug}', 'patient-survey-results-steps-prm-report')}&db=${Config.country}`
        if (removeFilterInactive) {
            url = url.replace('filter=includeInactive&', '')
        }

        return url
    }
    // Get all patient survey step results (now from the PRM).
    getPatientSurveyStepResults(patientJourneyId) {
        const url = this.getPatientSurveyStepResultsUrl(patientJourneyId)

        return Request.get(url).then(res => {
            return res.data
        })
    }

    // Get all patient exercise results.
    getExerciseResults(patientJourneyId) {
        const url = Request.Stem.patientJourneyReport
            .replace('{patientJourneyId}', patientJourneyId)
            .replace('{reportSlug}', 'patient-exercise-results-steps-report3')

        return Request.get(url).then(res => {
            return res.data
        })
    }

    /**
     * Return the map of report key (e.g. 'prem') to slug (e.g. 'practice-prem-report3')
     * NOTE: If the user does not have User.capability.canViewPatientData, we return an empty list
     */
    getReportSlugMap(user) {
        // TODO Need canViewAggregatedData ?
        const reportUrls = {}
        if (user.isAdmin) {
            return reportUrls
        }
        const owner = store.state.user.owner
        const reports = {}
        const ownerReportsConfig = owner.keyValues.dash.reports || {}
        if (!user.isAdmin && (user.has(User.Capability.canViewPatientData) || user.isExec)) {
            if (user.isExec) {
                reports.clinicianList = 'clinician-list-report3'
                if (user.isProviderExec) {
                    reports.departmentList = 'department-list-report3'
                }
            }
            if (user.has(User.Capability.canViewPatientData)) {
                reports.baseline = 'survey-average-report' // TODO check usage
                if (!owner.hasFeatureFlag('hasPatientJourneyList')) {
                    reports.prom = 'practice-survey-results-report3'
                }
            }

            const ownerReportsConfig = owner.keyValues.dash.reports || {}
            Object.keys(reports).forEach(key => {
                if (ownerReportsConfig[key]) {
                    // Use value defined in owner config
                    reportUrls[key] = `${ownerReportsConfig[key]}/`
                } else {
                    // Use value defined in fixed config
                    reportUrls[key] = reports[key]
                }
            })
        } else if (user.isPatient) {
            reportUrls.prom = 'patient-survey-results-prm-report'
            reportUrls.promSteps = 'patient-survey-results-steps-prm-report'
        }

        // For all non-admin personas
        if (user) {
            Object.keys(ownerReportsConfig).forEach(key => {
                if (ownerReportsConfig[key]) {
                    reportUrls[key] = ownerReportsConfig[key]
                }
            })
        }

        return reportUrls
    }
}

export default new ReportService()
