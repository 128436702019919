import Logging from '@serv/Logging'
import Utils from '@serv/Utils'

function storageMock() {
    let storage = {}

    return {
        setItem: function (key, value) {
            storage[key] = value
        },
        getItem: function (key) {
            return storage.hasOwnProperty(key) ? storage[key] : null
        },
        removeItem: function (key) {
            delete storage[key]
        },
        get length() {
            return Object.keys(storage).length
        },
        key: function (i) {
            const keys = Object.keys(storage)

            return keys[i] || null
        },
        clear: function () {
            storage = {}
        }
    }
}

/**
 * Store data in `localStorage` serialized as JSON.
 *
 * The API is similar to the Redis one:
 *
 *     set(key, value, expiry)
 *     get(key)
 *     remove(key)
 *
 * Supports versioning of storage formats.
 */
class Storage {
    constructor() {
        this.storage = Utils.isInTest() ? storageMock() : localStorage
    }

    /**
     * Get expiry timestamp relative to now.
     * @private
     */
    _getExpiryTS(expirySeconds) {
        let exp = Number(expirySeconds)
        if (!isNaN(exp) && exp > 0) {
            return Date.now() + exp * 1000
        }
        throw new Error('Expiry value is invalid')
    }

    /**
     * Check if the expiration is due.
     * @private
     */
    _isExpired(expiryDate) {
        let expDate = Number(expiryDate)

        return !isNaN(expDate) && expDate > 0 && Date.now() > expDate
    }

    /**
     * Set key to value
     * @param {String}
     * @param {Any}
     * @param {Number} expiry expiry in seconds
     */
    set(key, value, expiry = 0) {
        try {
            if (expiry > 0) {
                this.storage.setItem(`__${key}_expires__`, this._getExpiryTS(expiry))
            }

            return this.storage.setItem(key, JSON.stringify(value))
        } catch {
            throw new Error(`Storage set error for key: ${key}`)
        }
    }

    /**
     * Append key-value to an existing Object.
     */
    appendToObject(objectKey, key, value) {
        let target = this.get(objectKey)
        if (Utils.isPlainObject(target)) {
            target[key] = value
            this.set(objectKey, target)

            return true
        }
        throw new Error(`No such object: ${objectKey}`)
    }

    /**
     * Get key
     * @param {String}
     */
    get(key) {
        try {
            let expiry = this.storage.getItem(`__${key}_expires__`)
            if (isNaN(expiry) || this._isExpired(expiry)) {
                this.remove(key)

                return
            }

            return JSON.parse(this.storage.getItem(key))
        } catch {
            Logging.warn(`Storage get error for key: ${key}`)
        }
    }

    /**
     * Remove key
     * @param {String}
     */
    remove(key) {
        this.storage.removeItem(`__${key}_expires__`)

        return this.storage.removeItem(key)
    }

    getStorage() {
        return this.storage
    }
}

export default new Storage()
