import Logging from '@serv/Logging'

/**
 * The StringHelper service provides string helper functions.
 */
class StringHelper {
    // Capitalise only the first letter of a string. If string is falsy then return the string as is.
    capitalize(string) {
        if (!string) {
            return string
        }

        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    // Replace all occurences of a string, without using replaceAll (which is not supported in our mocha)
    replaceAll(text, from, to) {
        const parts = text.split(from)

        return parts.join(to)
    }

    // Remove all whitespace from a string (around and inside).
    removeWhitespace(string) {
        return string.replace(/\s+/g, '')
    }

    // Test if a string is all uppercase
    isUpperCase(string) {
        return string == string.toUpperCase()
    }

    // Convert slug (kebab-case) to camelCase.
    slugToCamelCase(slug) {
        const parts = slug.split('-')
        if (parts.length > 1) {
            let i = 0
            while (++i < parts.length) {
                if (this.isUpperCase(parts[i])) {
                    parts[i] = parts[i].toLowerCase()
                }
                parts[i] = this.capitalize(parts[i])
            }
            parts[0] = parts[0].toLowerCase()

            return parts.join('')
        }

        return slug.toLowerCase()
    }

    // Convert slug (kebab-case) to snake_case
    slugToSnakeCase(slug) {
        return this.replaceAll(slug, '-', '_').toLowerCase()
    }

    camelCaseToSlug(camelcase) {
        return camelcase.replace(/([A-Z])/g, '-$1').toLowerCase()
    }

    camelCaseToSnakeCase(camelcase) {
        return camelcase
            .replace(/([A-Z])/g, '_$1')
            .toLowerCase()
            .replace(/^_/, '')
    }

    // Hash a single string (to an integer).
    hashStringToInt(string) {
        let hash = 0,
            i,
            chr
        if (string.length == 0) {
            return hash
        }
        for (i = 0; i < string.length; i++) {
            chr = string.charCodeAt(i)
            hash = (hash << 5) - hash + chr
            hash |= 0 // Convert to 32bit integer
        }

        return hash
    }

    // Hash an array of strings (to an integer).
    hashStringsToInt(strings) {
        const string = strings.join('')

        return this.hashStringToInt(string)
    }

    /**
     * Generate slug from string - by lowercasing, removing chars that are not letter, digit or space,
     * splitting by space, truncating parts to maxPartLength (default 4), joining by dash, and truncating to maxLength (default 32).
     */
    stringToSlug(stringIn, config) {
        // https://stackoverflow.com/questions/6555182/remove-all-special-characters-except-space-from-a-string-using-javascript#comment96834760_6555220
        config = config || {}
        let string = stringIn.replace(/[^a-zA-Z0-9 ]/g, '')
        let parts = string.toLowerCase().split(' ')
        parts = parts.filter(part => part.length > 0)
        for (let i = 0; i < parts.length; i++) {
            parts[i] = parts[i].substring(0, config.maxPartLength || 4)
        }
        let slug = parts.join('-')
        slug = slug.substring(0, config.maxLength || 32)

        return slug
    }

    /**
     * We used to do this by regex, but it didn't allow + sign.
     * Would rather have a permissive approach here, rather than it starting to fail as the W3C spec for allowed
     * emails changes!
     */
    isValidEmail(email) {
        const atIndex = email.indexOf('@')
        const dotIndex = email.lastIndexOf('.')
        const spaceIndex = email.indexOf(' ')

        return atIndex > 0 && dotIndex > atIndex + 1 && dotIndex < email.length - 2 && spaceIndex < 0
    }

    isValidExternalId(externalId) {
        return /^\S{10}$/.test(externalId)
    }

    // Filter out all non-digit characters.
    removeNonDigits(stringIn) {
        const onlyNumbers = stringIn.replace(/\D/g, '')

        return onlyNumbers
    }

    numberStringToFloat(value) {
        if (typeof value == 'number') {
            return value
        }
        if (typeof value == 'string' && /^\d+(\.\d+)?$/.test(value)) {
            return parseFloat(value)
        }
        Logging.error(`Value (${value}), is in incorrect format.`)
    }

    getMetricHeightInAllUnits(height) {
        if (!Number.isNaN(height)) {
            const feet = Math.floor(height * 3.28084)
            const inches = Math.round(height * 39.3701 - feet * 12)

            return `${height.toFixed(2)}m / ${feet}ft ${inches}in`
        }
    }

    getMetricWeightInAllUnits(weight) {
        if (!Number.isNaN(weight)) {
            const stone = Math.floor(weight / 6.35029318)
            const pounds = Math.floor(weight * 2.204623 - stone * 14)
            const allPounds = Math.floor(weight * 2.204623)

            return `${weight.toFixed(0)}kg / ${stone}st ${pounds}lbs (${allPounds} lbs)`
        }
    }

    valueOrEmptyString(value) {
        return value || ''
    }

    removeSpacesFromString(value) {
        return this.valueOrEmptyString(value).replace(/\s/g, '')
    }

    // Return true only if the string is in UUID format
    isUuid(value) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

        return uuidRegex.test(value)
    }
}

export default new StringHelper()
