import store from '@/store'

export function _resolvePatientJourneyListColumnOfType_ptVirtualUser(column, patientJourney, row) {
    const user = store.state.user.users[row.ptVirtualClinicianId]

    if (user) {
        column.ptVirtualUser = user
        column.cellText = user.titledFullName
    }
}
