import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import ResourceService from '@serv/ResourceService'

class ListTransformPtUser extends ListTransform {
    constructor(object) {
        object.filterKey = 'ptClinicianId'
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const ptProviders = ResourceService.getPtProviders()

        return {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: ptProviders?.map(ptProvider => ({ value: ptProvider.value, label: ptProvider.title })),
            addAll: true,
            addNone: true
        }
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        if (keyStrings.length == 1 && keyStrings[0] == 'none') {
            return null
        }

        return [...Object.values(keyStrings)]
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'pt_user_name'
    }
}

export default ListTransformPtUser
