<template>
    <div class="date-picker__wrapper">
        <VcDatePicker
            :ref="pickerId"
            mode="date"
            :max-date="passingHigh"
            :initial-page="initialPage"
            :popover="{
                visibility: 'click',
                positionFixed: popoverPositionFixed,
                autoHide: false
            }"
            autocomplete="off"
            :locale="locale"
            :disabled="disabled"
            v-model="date"
        >
            <template #default="{ inputValue, inputEvents }">
                <p
                    v-if="date && !disabled"
                    class="clear"
                    @click=";(date = null)"
                />

                <span class="datepicker-icon">
                    <img
                        class="svg--grey"
                        :src="getIconUrl('icon-calendar.svg')"
                    />
                </span>

                <input
                    class="bg-white border px-2 py-1 padded"
                    :value="inputValue"
                    :disabled="disabled"
                    :placeholder="getPlaceholder()"
                    v-on="inputEvents"
                    @blur="onBlur"
                    @focus="onFocus"
                />
            </template>
        </VcDatePicker>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import useImage from '@composables/useImage'
import Utils from '@serv/Utils'
import { DatePicker as VcDatePicker } from 'v-calendar'

/* This datepicker supports the current specification.
 *  https://gitlab.myrecovery.ai/docs/docs/wikis/dash/design/date-picker-tool
 *
 *  Customization to a granular level can be made using links below as reference.
 *  https://github.com/nathanreyes/v-calendar/blob/e53eeb3b62b18098bfbeaa9de0877acd8f75f583/utils/defaults.js#L38
 *  https://github.com/nathanreyes/v-calendar/issues/39
 *
 *  As of September 2023, the linked docs from above do not work.
 *  The v-calendar docs can be found here instead:
 *  https://v-calendar-docs-2.netlify.app/
 *
 */
export default {
    name: 'DatePicker',
    emits: ['update:modelValue'],
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    components: {
        VcDatePicker
    },
    props: {
        ageCap: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        initialPage: {
            type: Object,
            default: () => {
                return {
                    month: moment().month() + 1, year: moment().year()
                }
            }
        },
        // eslint-disable-next-line vue/require-prop-types
        modelValue: { required: true },
        // eslint-disable-next-line vue/require-default-prop
        scope: { type: String },
        // eslint-disable-next-line vue/require-default-prop
        rangeLow: { type: Number },
        // eslint-disable-next-line vue/require-default-prop
        rangeHigh: { type: Number },
        // eslint-disable-next-line vue/require-prop-types
        procedure: { default: false },
        // eslint-disable-next-line vue/require-default-prop
        passedProcedure: { type: String },
        withIcon: { type: Boolean, default: false },
        // eslint-disable-next-line vue/require-default-prop
        placeholder: { type: String },
        popoverPositionFixed: { type: Boolean, default: false }
    },
    inject: ['$bus', '$config'],
    data() {
        return {
            date: undefined,
            reset: null,
            pickerSettings: {
                wrapper: { backgroundColor: 'white', border: '1px solid #dadada' }
            }
        }
    },
    computed: {
        ...mapGetters(['datePickerRefs', 'user']),
        pickerId() {
            return `picker_${nanoid()}`
        },
        locale() {
            if (this.user && ['au', 'gb', 'us'].includes(this.user.countryIso)) {
                return `en-${this.user.countryIso.toUpperCase()}`
            }

            return navigator ? navigator.language : `en-GB`
        },
        // Set the minimum age of 18 for GDPR compliance
        gdprAgeCap() {
            if (this.ageCap) {
                const now = moment()
                    .subtract(18, 'years')
                    .toISOString()

                return new Date(now)
            }

            return null
        },
        passingLow() {
            let now
            if (this.passedProcedure) {
                now = moment(this.passedProcedure)
                    .subtract(this.passedProcedure, 'years')
                    .toISOString()

                return new Date(now)
            }
            if (this.rangeLow) {
                now = moment()
                    .subtract(this.rangeLow, 'years')
                    .toISOString()

                return new Date(now)
            }

            if (this.procedure) {
                now = moment()
                    .add(this.rangeLow, 'years')
                    .toISOString()

                return new Date(now)
            }

            return null
        },
        passingHigh() {
            let now
            if (this.passedProcedure) {
                now = moment(this.passedProcedure)
                    .add(1, 'years')
                    .toISOString()

                return new Date(now)
            }
            if (this.rangeHigh && !this.procedure) {
                now = moment()
                    .subtract(this.rangeHigh, 'years')
                    .toISOString()

                return new Date(now)
            }

            if (this.procedure) {
                now = moment()
                    .add(this.rangeHigh, 'years')
                    .toISOString()

                return new Date(now)
            }

            return null
        }
    },
    methods: {
        // NOTE: this does not work reliably as a computed!
        getPlaceholder() {
            if (this.placeholder) {
                return this.placeholder
            }
            const event = new Date(Date.UTC(2021, 0, 31)) // 31 Jan 2021
            const dateFormat = event.toLocaleDateString(this.locale)
            const placeholder = dateFormat.startsWith('31')
                ? 'dd/mm/yyyy'
                : 'mm/dd/yyyy'

            return placeholder
        },
        updateDate(date) {
            if (date) {
                let dateValue = moment(date)
                if (dateValue.isValid()) {
                    this.$emit('update:modelValue', dateValue.format(Utils.serialisedDateFormat))

                    return
                }
            }
            this.$emit('update:modelValue', null)
        },
        /**
         * If the value is defined (will be a date string), set this.date to the Java Date
         * Else clear this.date
         */
        parseValue(value) {
            this.date = value ? moment(value).toDate() : undefined
        },
        onBlur(event) {
            if (!event.target.value) {
                this.$emit('update:modelValue', null)
            } else {
                this.updateDate(this.date)
            }
        },
        onFocus() {
            // Close all other date pickers that may be open
            Object.values(this.datePickerRefs).forEach(picker => {
                picker.popoverRef?.hide()
            })
        }
    },
    mounted() {
        this.parseValue(this.modelValue)
        this.$store.commit('addDatePickerRef', { pickerId: this.pickerId, pickerRef: this.$refs[this.pickerId] })
    },
    unmounted() {
        this.$store.commit('removeDatePickerRef', this.pickerId)
    },
    watch: {
        date(value) {
            this.updateDate(value)
        }
    }
}
</script>

<style lang="scss">
@import '../styles/colors';

.date-picker__wrapper {
    position: relative;

    .padded {
        width: 100%;
        padding-left: 50px !important;

        &::placeholder {
            color: $mediumGrey !important;
        }
    }
}

.field input {
    border: 1px solid #bbb !important;
}

.clear {
    position: absolute;
    inset: auto 5% -13% auto;
    width: 22px;
    height: 16px;
    opacity: 0.3;
}

.clear:hover {
    opacity: 1;
}

.clear::before,
.clear::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: $brandB;
}

.clear::before {
    transform: rotate(45deg);
}

.clear::after {
    transform: rotate(-45deg);
}

.datepicker-icon {
    position: absolute;
    top: 52%;
    transform: translate(80%, -50%);
}
</style>
