const state = {
    datePickerRefs: {}
}

const getters = {
    datePickerRefs: state => {
        return state.datePickerRefs
    }
}

const mutations = {
    addDatePickerRef(state, value) {
        state.datePickerRefs[value.pickerId] = value.pickerRef
    },
    removeDatePickerRef(state, value) {
        delete state.datePickerRefs[value]
    }
}
export default {
    state,
    getters,
    mutations
}
