import store from '@/store'

export function _resolvePatientJourneyListColumnOfType_ptUser(column, patientJourney, row) {
    const user = store.state.user.users[row.ptClinicianId]

    if (user) {
        column.ptUser = user
        column.cellText = user.titledFullName
    }
}
