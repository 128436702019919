<template>
    <Loader class="ui grid" />
</template>

<script>
import Loader from '@comp/MrLoader.vue'
import LoginService from '@serv/LoginService'
import Redirect from '@serv/Redirect'

/**
 * We always log out by navigating to this route.
 *  - remove whoami data
 *  - remove the 401 callback as this will trigger `credentials expired` errors on future logins if the user puts in
 * the wrong credentials.
 */
export default {
    name: 'MrLogout',
    components: { Loader },
    methods: {
        doLogout() {
            LoginService.logout().then(() => {
                Redirect.replacePath('/') // go to front page
            })
        }
    },
    mounted() {
        this.doLogout()
    },
    updated() {
        this.doLogout()
    }
}
</script>
