import _ from 'lodash'
import Analytics from '@serv/Analytics'
import Logging from '@serv/Logging'
import LoginService from '@serv/LoginService'
import Milestone from '@model/Milestone'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import NotifyService from '@serv/NotifyService'
import Redirect from '@serv/Redirect'
import ReportService from '@serv/ReportService'
import Request from '@serv/Request'
import ResourceService from '@serv/ResourceService'
import Schedule from '@model/Schedule'
import store from '@/store'
import SurveyService from '@serv/SurveyService'

import Utils from '@serv/Utils'

/**
 * Helpers for managing the patient registration process, including consent (Terms).
 */
class RegistrationService {
    /**
     * Initialise from a given route name.
     * For example: if PortalRegistration1Or2Fa, make the request to get the Patient object, with the results
     * being placed in the webRegistration store.
     */
    initFromRouteName() {
        const routeName = Redirect.router.currentRoute.value.name
        store.commit('setIsWebRegistration', true)
        switch (routeName) {
            case 'PatientInviteLegacy':
                this.getClinicianAssets()
                break
            case 'PortalRegistration1Or2Fa':
            case 'PortalRegistrationSelfNhs':
                if (store.state.user.user) {
                    this.getClinicianAssets()
                }
                break
            case 'PortalRegistrationHospitalNumber': {
                // NOTE: If reaching this page after a previous hospital number auth, the type should already be set
                // in the state
                const hospitalNumberType = Redirect.router.currentRoute.value.query.type
                if (hospitalNumberType) {
                    store.commit('setHospitalNumberAuthType', hospitalNumberType)
                }
                if (hospitalNumberType == 'nhs') {
                    store.commit('setIsNhs', true)
                }
                break
            }
        }
    }

    getClinicianAssets() {
        Logging.log('Requesting registration assets...')
        Request.get(Request.Stem.selfRegistrationComplete)
            .then(res => {
                store.commit('setPatientRegistrationResponseData', res.data)
            })
            .catch(error => NotifyService.error(error))
    }

    getReportsMap() {
        const patient = store.state.user.user
        const surveyResultsUrl = ReportService.getPatientSurveyResultsUrl(patient.primaryJourney.patientJourneyId, true)
        const stepResultsUrl = ReportService.getPatientSurveyStepResultsUrl(
            patient.primaryJourney.patientJourneyId,
            true
        )

        return {
            prom: surveyResultsUrl,
            promSteps: stepResultsUrl
        }
    }

    /**
     * If NO resources loaded, make requests for milestones, journeys, content.
     * Also include any required reports.
     * Then call standard resolution of all resources/reports.
     */
    ensureJourneysContent() {
        const contentToLoad = ['content']
        const resourcesToLoad = ['journeys', 'milestones']
        const promises = []

        contentToLoad.forEach(contentType => {
            if (!store.state.content[contentType]) {
                promises.push(contentType)
            }
        })

        resourcesToLoad.forEach(resourceType => {
            if (!store.state.resources[resourceType] || !store.state.resources[resourceType].length) {
                promises.push(resourceType)
            }
        })

        if (promises.length) {
            return ResourceService.makePromiseForAllResourcesAndReports(promises, this.getReportsMap())
        }
    }

    /**
     * If NO resources loaded, make requests for milestones, journeys, content, teams, teammembers.
     * Also include any required reports.
     * Then call standard resolution of all resources/reports.
     */
    ensureJourneysContentTeamsUsers() {
        if (!store.state.content.content) {
            let requests = ['content', 'journeys', 'teams', 'teamMembers']

            // Milestones might be already requested in Whoami.getActiveUser(), make sure we are not triggering request twice
            if (!store.state.resources.milestones) {
                requests.push('milestones')
            }

            return ResourceService.makePromiseForAllResourcesAndReports(requests, this.getReportsMap())
        }

        return Promise.resolve()
    }

    /**
     * The register endpoint does not return a registration PJM, so we'll add one here, even if we don't have the BE
     * id for it.
     */
    ensureRegistrationMilestone() {
        const patient = store.state.user.user
        const regDate = patient.registrationDate
        if (!regDate) {
            const regMilestone = new Milestone({
                type: Milestone.Type.default,
                slug: 'registration',
                date: moment().format(Utils.serialisedDateFormat)
            })
            patient.globalJourney.addMilestone(regMilestone, true)
        }
    }

    /**
     * Move to the next step of the registration process.
     * This will change the route, depending on the state of the Patient and webRegistration store.
     */
    nextStep(query) {
        const routeName = this.getNextRouteNameFromRouteWithPatient(
            Redirect.router.currentRoute.value.name,
            store.state.user.user
        )
        Redirect.gotoName(routeName, undefined, query)
    }

    // Get next route name.
    getNextRouteNameFromRouteWithPatient(routeName, patient) {
        let nextRouteName
        switch (routeName) {
            case 'PortalRegistrationLogin':
                if (patient.isConsented) {
                    this.ensureJourneysContentTeamsUsers()
                    nextRouteName = 'SurveysRoot'
                } else {
                    nextRouteName = 'PortalRegistrationTerms'
                }
                break
            case 'PortalRegistration1Or2Fa':
            case 'PortalRegistrationSelf2':
                if (patient.isConsented) {
                    this.ensureJourneysContentTeamsUsers()
                    nextRouteName = 'SurveysRoot'
                } else {
                    nextRouteName = 'PortalRegistrationTerms'
                }
                break
            case 'PortalRegistrationTerms':
                if (store.state.portalRegistration.isWebRegistered) {
                    nextRouteName = 'PortalRegistrationSuccess'
                } else {
                    nextRouteName = 'SurveysRoot'
                }
                break
            case 'PortalRegistrationSuccess':
                this.ensureJourneysContentTeamsUsers()
                nextRouteName = 'SurveysRoot'
                break
            case 'PortalRegistrationSelfNhsPain':
                if (store.state.portalRegistration.isWebRegistered) {
                    nextRouteName = 'PortalRegistrationSelfNhsTerms'
                } else {
                    nextRouteName = 'PortalRegistrationSelfNhsSuccess'
                }
                break
            case 'PortalRegistrationReferAuth':
                if (!SurveyService.getPatientWebSurveyBySlug('stw-self-referral-surv')) {
                    store.dispatch('selfReferral/showNoActiveSurveyModal')
                    nextRouteName = ''
                } else {
                    nextRouteName = 'PortalRegistrationReferSurvey'
                }

                break

            case 'PortalRegistrationReferTerms':
                nextRouteName = 'PortalRegistrationReferSurvey'
                break
            case 'PortalRegistrationReferDetails':
                nextRouteName = 'PortalRegistrationReferTerms'
                break
            case 'PortalRegistrationSelfNhsTerms':
                nextRouteName = 'PortalRegistrationSelfNhsSuccess'
                break
            case 'PortalRegistrationReferFinish':
                nextRouteName = 'PortalRegistrationReferSuccess'
                break
        }

        return nextRouteName
    }

    // Get a single onboarding terms Info object
    getTermsInfo() {
        return Object.values(store.state.content.content).find(content => content.containsTag('onboarding-terms'))
    }

    // Get a single onboarding terms Activity object
    getTermsActivity() {
        const content = this.getTermsInfo()
        if (content) {
            const patient = store.state.user.user
            const journey = store.state.resources.journeys?.[patient.firstJourney.journeySlug]

            return journey?.activities.find(activity => activity.contentSlug == content.slug)
        }
    }

    // Send an ActivityResult for the Terms page, and a number of EventConsents.
    postConsentEvents(consentTypes) {
        // Send ActivityResult
        const activity = this.getTermsActivity()
        const schedule = Schedule.get(activity.scheduleSlug)
        const patient = store.state.user.user
        const nowTime = moment().format(Utils.serialisedDateTimeFormat)
        // const uuid = nanoid()
        const milestone = patient.getMilestoneOfSlug(schedule.milestone)
        const infoResultPayload = {
            milestoneSlug: schedule.milestone,
            contentSlug: activity.contentSlug,
            activitySlug: activity.slug,
            patientJourneyId: patient.firstJourney.patientJourneyId,
            type: 'infoResult',
            status: 'complete',
            patientJourneyMilestoneId: milestone?.id,
            multiple: 0,
            repeatIndex: 0,
            startTime: nowTime,
            endTime: nowTime,
            syncTime: nowTime,
            lastSyncTime: nowTime,
            deviceOsVersion: Utils.getBrowser(),
            // navigator.platform has been deprecated from most browser support, we should update to match part of the string from navigator.userAgent
            devicePlatform: navigator.platform,
            appVersion: '1.0.0', // "special" version number indicates coming from dash not app
            isWebSurveyResult: true,
            uuid: nanoid()
        }
        let url = Request.Stem.patientTermsPageInfoResultCreate.replace('{patientId}', patient.patientId)
        Request.post(url, infoResultPayload)
            .then(() => {
                // Nothing
            })
            .catch(error => {
                NotifyService.errorWithResponse(error.response)
            })

        // Send Events
        const allTypes = ['global', ...consentTypes]
        url = Request.Stem.patientTermsPageConsentEventCreate.replace('{patientId}', patient.patientId)
        allTypes.forEach(consentType => {
            const eventPayload = {
                type: 'consent',
                deviceOsVersion: Utils.getBrowser(),
                devicePlatform: navigator.platform,
                appVersion: '1.0.0',
                consentType: consentType,
                contentSlug: activity.contentSlug,
                startTime: nowTime,
                syncTime: nowTime,
                lastSyncTime: nowTime
            }
            Request.post(url, eventPayload)
                .then(() => {
                    patient.consentSlugs = _.uniq(patient.consentSlugs || [], consentType)
                })
                .catch(error => {
                    NotifyService.errorWithResponse(error.response)
                })
        })
        Analytics.sendEvent('patientConsent', {
            contentType: 'global',
            contentSlug: activity.contentSlug
        })
        this.nextStep()
    }

    // Following some web registration activity, log the patient out and return to the appropriate starting page
    logoutAndRedirect() {
        let routeName
        const state = store.state.portalRegistration
        if (state.isStartingAtFrontPage) {
            routeName = state.isNhs ? 'PortalRegistrationFrontNhs' : 'PortalRegistrationFront'
        } else if (state.hospitalNumberAuthType) {
            routeName = 'PortalRegistrationHospitalNumber'
        } else {
            routeName = 'PortalRegistrationLogin'
        }
        LoginService.logout().then(() => {
            // clears Vuex state, so must be last!
            Redirect.gotoName(routeName)
        })
    }
}

export default new RegistrationService()
