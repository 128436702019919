<template>
    <VueDatePicker
        ref="datePicker"
        :placeholder="placeholder"
        :text-input="true"
        v-model="date"
        v-bind="{ ...currentConfig?.props }"
        @text-submit="onTextSubmit"
        @cleared="onChange"
        v-on="{ ...currentConfig?.actions }"
    >
        <!-- empty slots for action-preview and action-buttons are to remove buttons from datepicker. https://vue3datepicker.com/slots/content/#action-buttons -->
        <template #action-preview><div /></template>
        <template #action-buttons><div /></template>
    </VueDatePicker>
</template>

<script setup>
import moment from 'moment'
import Utils from '@serv/Utils'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, nextTick, ref } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
    dateFormat: {
        type: String,
        default: 'yyyy-mm-dd',
        // 'date' is an option just to have backward compatibility. Means same as 'yyyy-mm-dd'.
        validator: (v) => ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy'].includes(v)
    },
    placeholder: {
        type: String,
        default: ''
    }
})

// update model value and emit to parent
const datePicker = ref()
const date = ref()

const onChange = (value) => {
    if (value) {
        /*
        By default, vue-datepicker works with 'select' and 'cancel' buttons. Buttons removed to be consistent with the existing DatePicker, which is working without control buttons.
        To control model change, we are using @dateUpdate and @updateMonthYear. These events emit values earlier than overwrite the internal modelValue.
        nextTick() is to wait for the next event loop cycle and update the modelValue on the next iteration of the event loop.
         */
        nextTick().then(() => {
            date.value = value
            datePicker.value.selectDate()

            let dateMoment = moment(value)
            if (dateMoment.isValid()) {
                emits('update:modelValue', dateMoment.format(currentConfig.value.format))
            }
        })
    } else {
        emits('update:modelValue', undefined)
    }
}

// month year DatePicker.
const monthAndYear = ref({ year: undefined, month: undefined })

const onChangeMonthYear = (value) => {
    /**
    vue-datepicker provides only a method to update AND year AND month, and it calls it when user change year by arrows.
    Skip update model when the year changes in a month picker to prevent model change and closing calendar.
    */
    if (
        props.dateFormat === 'yyyy-mm' &&
        monthAndYear.value?.year &&
        value?.year !== monthAndYear.value?.year
    ) {
        monthAndYear.value = value

        return
    }

    monthAndYear.value = value
    onChange(value)
}

// action on type date manually in input
const onTextSubmit = () => {
    onChange(date.value)
}

// configuration map for different picker types
const datePickerConfig = {
    'yyyy-mm-dd': {
        format: Utils.serialisedDateFormat,
        props: {
            enableTimePicker: false
        },
        actions: {
            dateUpdate: onChange
        }
    },
    'yyyy-mm': {
        format: 'yyyy-mm',
        props: {
            monthPicker: true
        },
        actions: {
            updateMonthYear: onChangeMonthYear
        }
    },
    'yyyy': {
        format: 'yyyy',
        props: {
            yearPicker: true
        },
        actions: {
            updateMonthYear: onChangeMonthYear
        }
    }
}

const currentConfig = computed(() => {
    return datePickerConfig[props.dateFormat] || datePickerConfig['yyyy-mm-dd']
})
</script>
