<template>
    <div
        v-if="$route.meta.requiresAuth"
        id="user"
    >
        <div class="profile-menu">
            <div class="profile-menu-wrapper">
                <div class="avatar">
                    <Avatar
                        size="small"
                        :user="user"
                    />
                </div>
                <div class="current-user">
                    <p class="user-name">
                        {{ displayName }}
                    </p>
                    <p class="position">
                        {{ displayPosition }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@serv/Auth'
import Avatar from '@comp/MrAvatar.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Utils from '@serv/Utils'
import Whoami from '@serv/Whoami'

/**
 * Render the User component
 */
export default {
    name: 'MrUser',
    components: { Avatar },
    inject: ['$bus', '$config'],
    data() {
        return {
            currentDate: null
        }
    },
    computed: {
        ...mapGetters(['surgeons', 'user']),
        displayName() {
            return this.user.isSurgeon ? this.user.titledLastName : this.user.fullName
        },
        displayPosition() {
            // If the user has a defined 'role' which we have a dashstring key for, use that.
            // Else use the 'persona', which is assumed to have a dashstring key.
            return this.user.personaLabel
        },
        avatarUrl() {
            return this.user.avatarUrl
        }
    },
    methods: {
        backgroundColor(config, user) {
            return Utils.backgroundColor(config, user)
        },
        getActiveUserAndPermissions() {
            if (Auth.hasSession()) {
                Whoami.getActiveUser()
            }
            this.currentDate = moment().format('Do MMMM YYYY')
        }
    },
    mounted() {
        this.$bus.$on('login', this.getActiveUserAndPermissions)
        this.$bus.$on('register', this.getActiveUserAndPermissions.bind(this, true))
        this.getActiveUserAndPermissions()
    },
    beforeUnmount() {
        this.$bus.$off('login')
        this.$bus.$off('register')
    },
    unmounted() {
        document.removeEventListener('login', this.getActiveUserAndPermissions)
        document.removeEventListener('register', this.getActiveUserAndPermissions.bind)
    },
    updated() {
        $(this.$refs.navDrop).dropdown()
    }
}
</script>

<style lang="scss">
#user {
    .profile-menu {
        display: flex;
        min-width: 311px;
        height: 72px;
        margin-bottom: 45px;
    }

    .profile-menu-wrapper {
        position: relative;
        width: 100%;
    }

    .current-user {
        padding-left: 26px;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 278px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .user-name {
            font-family: Omnes Medium;
        }

        .position {
            width: 200px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .user-name {
        font-weight: 700;
    }
}
</style>
