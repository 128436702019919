/* eslint no-console: "off" */
import * as Sentry from '@sentry/vue'
import Analytics from '@serv/Analytics'
import ConfigManager from '@config/ConfigManager'
import Storage from '@serv/Storage'

/**
 * The Logging service logs messages to the console.
 * Debug messages can be toggled on or off by setting `localStorage.debug` to `1` or `0`
 */
class Logging {
    /** @private */
    _log(level = 'log', message) {
        let Levels = ['log', 'info', 'warn', 'error']

        if (!Levels.includes(level)) {
            throw new Error('Invalid logging level:', level)
        }

        let displayLevel = `${level.toUpperCase()} »`

        console[level](displayLevel, ...message)
    }

    /**
     * Log message
     * @param {String|Array}
     */
    log(...message) {
        if (Storage.get('debug') >= 2 || ConfigManager.isMockingServer) {
            this._log('log', message)
        }
    }

    /**
     * Log info message
     * @param {String|Array}
     */
    info(...message) {
        this._log('info', message)
    }

    /**
     * Log warning message
     * @param {String|Array}
     */
    warn(...message) {
        this._log('warn', message)
    }

    /**
     * Log error message.
     * If exactly 2 params provided and second === 'true', we also log the error as a Mixpanel event.
     * @param {String|Array}
     */
    error(...message) {
        let textArray
        if (Array.isArray(message) && message.length == 2 && message[1] === true) {
            Analytics.sendEvent('error', { message: message[0] })
            textArray = [message[0]]
        } else {
            textArray = message
        }
        this._log('error', textArray)
        // Send to Sentry, with callstack
        Sentry.captureException(new Error(textArray))
    }
}

export default new Logging()
