import Config from '@serv/Config'
import Storage from '@serv/Storage'

const FeatureFlag = {
    rtmPeriodRefactor: 'RTM_PERIOD_REFACTOR'
}
let featureFlags

export function useFeatureFlags() {
    const getFeatureFlags = () => {
        return featureFlags
    }

    const getFeatureFlag = flagName => {
        let storageValue
        if (Config.environment != 'production') {
            storageValue = Storage.get(`featureFlag${flagName}`)
        }

        return typeof storageValue == 'boolean' ? storageValue : featureFlags?.[flagName]?.enabled
    }

    const setFeatureFlags = newFeatureFlags => {
        featureFlags = newFeatureFlags.reduce((map, flag) => {
            map[flag.id] = flag

            return map
        }, {})
    }

    return {
        FeatureFlag,
        featureFlags,
        getFeatureFlag,
        getFeatureFlags,
        setFeatureFlags
    }
}
